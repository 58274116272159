import { Component, HostListener, Input, OnInit } from "@angular/core";

import { NbMenuService, NbSidebarService } from "@nebular/theme";
import { UserData } from "../../../@core/data/users";
import { AnalyticsService } from "../../../@core/utils";
import { LayoutService } from "../../../@core/utils";
import { NotificationsService } from "../../../services/notifications/notifications.service";
import { Router } from "@angular/router";
import { MenuService } from "../../../services/menu/menu.service";
import { UserService } from "../../../services/user/user.service";
import { GlobalService } from "../../../services/global/global.service";
import { TestServiceService } from "../../../services/test_service/test-service.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Nav_pill_add__002_005 } from "../../../shared/components/_#002__forms/_comp__#002_#005__nav_pill_add/nav_pill_add__002_005.component";
import { HttpClient } from "@angular/common/http";
import { ApiConstData } from "../../../../consts/ApiConstData";
import { TranslateService } from '@ngx-translate/core';
import { PusherService } from '../../../services/pusher/pusher.service'
import { GlobalFunctions } from '../../../../consts/global-functions';
import { dataBound } from '@syncfusion/ej2-angular-schedule';

import {ProfileService} from '../../../services/profile/profile.service';


@Component({
  selector: "ngx-header",
  styleUrls: ["./header.component.scss"],
  templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit {
  @Input() position = "normal";
 

  userInfo: any;
  action_msg: string = "";
  user: any;
  unread;
  direction: string;

  // actionLoading:Boolean = false;

  userMenu = [{ title: `الصفحة الشخصية`,link:"/pages/static-pages/profile" }, { title: `تسجيل الخروج`, link: "/logout" }];
  // userMenu = [{ title: `تسجيل الخروج`, link: "/logout" }];
  notificationsList = [];
  notificationsListLength;

  ProfileData;

  isHide: boolean = false;


  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    // private userService: UserData,
    private analyticsService: AnalyticsService,
    private layoutService: LayoutService,
    private notificationService: NotificationsService,
    private router: Router,
    private settingsService: MenuService,
    private userService: UserService,
    private menuServiceApi: MenuService,
    private globalService: GlobalService,
    private testService: TestServiceService,
    private modal: NgbModal,
    private httpClient: HttpClient,
    private apiConsts: ApiConstData,
    private translateService: TranslateService,
    private pusherService: PusherService,
    private GlobalFunctions: GlobalFunctions,
    private profileservice:ProfileService

  ) {
  }

  ngOnInit() {
   
    console.log("test pusher")
    this.pusherService.channel.bind('notifications_event', data => {
      this.GlobalFunctions.showToastr('success', 'اشعار',`${data.notification_text}`, 'fa fa-volume-up')
    });
    this.direction = this.globalService.get_direction_class();
    this.getAllNotifications();
    this.userService.updateProfileRef.subscribe(userInfo=>{
      this.userInfo = JSON.parse(userInfo);
      console.log("source of error :-",this.userInfo)
    });
    this.userInfo = this.userService.getUserInfo();
    this. GetProfileData();
    }

    GetProfileData(){
      this.profileservice.GetProfileInfo().subscribe((res:any)=>{
          this.ProfileData = res.content;
          localStorage.setItem('userID',res.content.id)
      })
    }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, "menu-sidebar");
    this.layoutService.changeLayoutSize();

    return false;
  }

  goToHome() {
    this.menuService.navigateHome();
  }

  startSearch() {
    this.analyticsService.trackEvent("startSearch");
  }
  
  showNotification(event) {
    console.log("event=> ",event)
    event.stopPropagation();
    this.isHide = !this.isHide; // toggle the notification 
    var element = document.getElementById("notificationIcon");
    element.classList.remove("visibility--hidden");
    // event.stopPropagation()
  }
  @HostListener("document:click") hideOnClick() {
    this.isHide = true;
    var element = document.getElementById("notificationIcon");
    element.classList.add("visibility--hidden");

  }

  

  getAllNotifications() {
    // this.notificationsList = this.notificationService.getNotificationsList();
    // console.log("notification /*/*")
    // console.log(this.notificationsList)
    this.notificationService.getNotification().subscribe((response) => {
      console.log("notifivarop => ", response);
      this.notificationsList = response["content"].data;
      // this.notificationsList = this.testService.notification_list.content.data;
      console.log("notificationList => ", this.notificationsList);
      this.unread = response["content"].count;
    });
  }

  notificatinAction(notificationItem) {
    if (notificationItem.interaction_type == "layout" ) {
      console.log("notification Item:----------->", notificationItem);
      console.log("notification Item Interaction Type :----------->", notificationItem.interaction_type);

      let layoutLink = this.menuServiceApi.getLayoutByID(
        notificationItem["menu"].layout_id
      );
      this.notificationService.readNotification(notificationItem.id).subscribe(response=>{
        sessionStorage.removeItem("queryParams");
        this.router.navigate([layoutLink], {
          queryParams: {
            componentsList: JSON.stringify(notificationItem["menu"].components),
            // children: JSON.stringify(notificationItem["menu"].children),
            fromWhere: 'notification'
          },
        });
      })
     
    } 

    else if (notificationItem.interaction_type == "show_more"){
      console.log("API of show more notifications:------",notificationItem)
      console.log("component list of notification :- ", JSON.stringify(notificationItem["menu"].components))
      this.notificationService.showallNotification().subscribe(res=>{
        // sessionStorage.setItem('activeTab',"notifications");
        sessionStorage.removeItem("queryParams");

        this.router.navigate([`${'/pages/layouts/layout1'}`], {
          queryParams: {
            componentsList: JSON.stringify(notificationItem["menu"].components),
            // children: JSON.stringify(notificationItem["menu"].children),
            // children: "[{\"id\":3661,\"title\":\"الإشعارات\",\"order\":1,\"icon\":n…\":513,\"template_id\":1544},\"component_data\":[]}]}]",
            fromWhere: 'notification'
          },
        });

      })
    }
   
    else if (notificationItem.interaction_type == "modal_form") {
      console.log("modal_form interaction=> ");
      this.httpClient
        .post<any>(`${notificationItem.api}`, notificationItem.api_parameters, {
          headers: this.apiConsts.getHeaderandBody().apiHeaders,
        })
        .subscribe((response) => {
          console.log("response of form  => ", response);
          const activeModal = this.modal.open(Nav_pill_add__002_005, {
            size: "lg",
            container: "nb-layout",
          });
          activeModal.componentInstance.formStructure = response.content;
        });
    }
  }

  handleBtnAction(button, index) {
    $(`#action_loading_${index}`).removeClass("display--none");
    setTimeout(() => {
      $(`#action_loading_${index}`).addClass("display--none");

      if (button.target_action == "message") {
        console.log("msg=> ", button.action_msg);
        $("#notification_actionBtn").addClass("animated fadeOut");
        $("#notification_actionBtn").addClass("display--none");
        this.action_msg = button.action_msg;
        $("#action_msg").addClass("animated fadeIn");
      }
    }, 5000);
    console.log(button);
  }
   
  showmorenotification(){
    
  }

}
