import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../../../services/global/global.service';
import {ProfileService} from '../../../../services/profile/profile.service';


@Component({
  selector: 'log__006_003',
  templateUrl: './log__006_003.component.html',
  styleUrls: ['./log__006_003.component.scss']
})
export class Log__006_003 implements OnInit {
  LogData;
  LogUserID;
  constructor(private profileservice:ProfileService,
    private globalVariables: GlobalService,
    
    ) { }

  ngOnInit() {
    this.GetLogData();
    this.LogUserID =localStorage.getItem('userID');
    // console.log("userId is =======>",this.LogUserID)
    // Show the first tab by default
    $('.tabs-stage div.tab-content').hide();
    $('.tabs-stage div:first').show();
    $('.tabs-nav li:first').addClass('tab-active');
    
    // Change tab class and display content
    $('.tabs-nav a').on('click', function(event){
      event.preventDefault();
      $('.tabs-nav li').removeClass('tab-active');
      $(this).parent().addClass('tab-active');
      $('.tabs-stage div.tab-content').hide();
      $($(this).attr('href')).show();
    });
  }

  GetLogData(){
     this.profileservice.GetLogInfo(this.LogUserID ).subscribe((res:any)=>{
       this.LogData = res.content;
        this.globalVariables.setCompLoadedNum();
       
     },error=>{
        this.globalVariables.setCompLoadedNum();

     })
  }

}
