import { Injectable } from '@angular/core';

const Loading_primaryColour ="#ffffff";
const Loading_secondaryColour ="#ffffff"
const Loading_bg_radius ="3px";
@Injectable({providedIn:'root'})

export class Configurations {
    

    loadingConfig = {primaryColour: Loading_primaryColour,
                    secondaryColour: Loading_secondaryColour,
                    backdropBorderRadius: Loading_bg_radius}

    /*Global Msgs Related to API */
        apiResponseMsg = {
            'danger': {
                'icon':'fas fa-times',
                'msg':'There is Unknown Error, Please Try again :)'
            },
            'warning': {
                'icon':'fas fa-exclamation',
                'msg':'There is No Data'
            },
            'success': {
                'icon':'fas fa-check',
                'msg':'The Data Submitted Successfully :)'
            }

        }
        
    }



