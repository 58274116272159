import { Component, OnInit } from '@angular/core';
import {ProfileService} from '../../../../services/profile/profile.service';

@Component({
  selector: 'card__003_007',
  templateUrl: './card__003_007.component.html',
  styleUrls: ['./card__003_007.component.scss']
})
export class Card__003_007 implements OnInit {

  ProfileData;

  constructor(private profileservice:ProfileService) { }

  ngOnInit() {
      this.GetProfileData();
  }

  GetProfileData(){
    this.profileservice.GetProfileInfo().subscribe((res:any)=>{
        this.ProfileData = res.content;
        localStorage.setItem('userID',res.content.id)
        console.log("Profile Data:-",this.ProfileData)
    })
  }

  goToExternalLink(link) {
    window.open(link,'_blank')
  }

}
