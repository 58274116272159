import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input } from '@angular/core';
import { moveItemInArray, CdkDragDrop, transferArrayItem, CdkDrag } from '@angular/cdk/drag-drop';
import { TrelloService } from '../../../../../../services/trello/trello.service';
import { TestServiceService } from '../../../../../../services/test_service/test-service.service';
import { ModalComponent } from '../../../../../modal/modal.component';
import { Nav_pill_add__002_005 } from '../../../../_#002__forms/_comp__#002_#005__nav_pill_add/nav_pill_add__002_005.component'
import { GlobalService } from '../../../../../../services/global/global.service';
import { GlobalFunctions } from '../../../../../../../consts/global-functions';
import { Configurations } from '../../../../../../../consts/configurations';
import { HttpClient } from '@angular/common/http';
import { ApiConstData } from '../../../../../../../consts/ApiConstData';
import { Pipeline_modal__012_001_005 } from '../_comp__#012_#001_005__pipeline_modal/pipeline_modal__012_001_005.component';
import { element } from '@angular/core/src/render3';

@Component({
  selector: 'pipeline_container__012_001_004',
  templateUrl: './pipeline_container__012_001_004.component.html',
  styleUrls: ['./pipeline_container__012_001_004.component.scss']
})
export class Pipeline_container__012_001_004 implements OnInit {
  // @Input() textValue: string;
  // @Input() count: number;
  // @Input() apiUrl: string;
  @Input() apiLink;
  @Input() apiParameters;
  loading:Boolean;
  loadingConfig;
  stages = [];

  fields = [];
  stageFieldsTest = [];
  status;
  hasStatus=false;
  cardID;
  element;
  constructor(
    private trelloService: TrelloService,
    private modal: NgbModal,
    private testService: TestServiceService,
    private GlobalFunctions: GlobalFunctions,
    public config: Configurations,
    private globalVariables: GlobalService,
  ) { }

  ngOnInit() {
   
    this.trelloService.getStages(this.apiLink, this.apiParameters).subscribe(response => {
      console.log("response in pipeline container=> ", response)
      this.globalVariables.trelloTasks = response.content.trello;
      this.globalVariables.setCompLoadedNum();
      // console.log("compNumTable=> ",this.globalVariablesVariables.compLoadedNum)
    }, error => {
      this.globalVariables.setCompLoadedNum();
      this.GlobalFunctions.showToastr('danger', 'Error', this.config.apiResponseMsg.danger.msg, this.config.apiResponseMsg.danger.icon)
    })
  }

  ngDoCheck() {
    this.stages = this.globalVariables.trelloTasks;
    console.log("List of stages => ", this.stages)
  }

  showCardInput(listIndex) {
    console.log("cardContainerID =>", listIndex);
    $(`#new-card-widget-container-${listIndex}`).css('display', 'block');
    $(`#add-${listIndex}`).css('display', 'none')
  }

  hideCardWidgetContainer(listIndex) {
    $(`#new-card-widget-container-${listIndex}`).css('display', 'none')
    $(`#add-${listIndex}`).css('display', 'block')
  }

  addCardToList(listIndex) {
    this.loading = true;
    let inputTxt = $(`#new-card-widget-input-${listIndex}`).val()
    console.log("input Text => ",inputTxt)
    this.globalVariables.trelloTasks[listIndex]['tasks'].push({ 'label': inputTxt });
    $(`#new-card-widget-input-${listIndex}`).val('')
    console.log("stageApi => ", this.stages[listIndex].api);
    console.log("stageparameters => ", this.stages[listIndex].api_parameters);
    let apiParameters = {...this.stages[listIndex].api_parameters,...{name:inputTxt}}
    this.globalVariables.getResponse(this.stages[listIndex].api,apiParameters).subscribe(response => {
      console.log("response in create task => ",response);
      if(response["status"].code != 200) {
        this.globalVariables.trelloTasks[listIndex]['tasks'].pop();
      }
    },error=>{
      this.GlobalFunctions.showToastr('danger', 'Error', this.config.apiResponseMsg.danger.msg, this.config.apiResponseMsg.danger.icon)
      this.globalVariables.trelloTasks[listIndex]['tasks'].pop();
    })
  }

  drop(event: CdkDragDrop<string[]>, stageIndex) {
    console.log("stageIndex=> ", stageIndex)
    console.log("events=> ", event)
    console.log("test container => ", event.previousContainer)
    let prevContainer = parseInt(event.previousContainer.id)
    let currentContainer = parseInt(event.container.id);
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      let stageFields = this.stages[prevContainer].form;
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
      let task_details = { request_id: this.stages[currentContainer].tasks[event.currentIndex].request_id,request_type_id:this.stages[currentContainer].request_type_id };
      stageFields['parameters'] = { ...stageFields['parameters'], ...task_details}
      const activeModal = this.modal.open(Nav_pill_add__002_005, { size: 'lg', container: 'nb-layout' });
      activeModal.componentInstance.formStructure = stageFields;
      activeModal.componentInstance.formResponse.subscribe(formStatus => {
        if (formStatus == false) {
          transferArrayItem(
            event.container.data,
            event.previousContainer.data,
            event.currentIndex,
            event.previousIndex);
          activeModal.close();
        }
        else {
          activeModal.close();
        }

      })

    }
  }

  acceptPredicate(item) {
    console.log("predicate=> ", item)
    // return item.data % 2 === 0;
  }

  getdescription(card,stage_index)
  {
    console.log("card>>>>>",card);
    
    const activeModal = this.modal.open(Pipeline_modal__012_001_005, { size: 'lg', container: 'nb-layout' });
    activeModal.componentInstance.title =card.label;
    activeModal.componentInstance.TaskID =card.request_id;
    activeModal.componentInstance.ParentTaskID=stage_index;
    

    this.cardID=card.request_id;
    activeModal.componentInstance.StatusChange.subscribe(response=>{
      console.log("response",response);
      this.element=this.stages[stage_index].tasks.find(element=>
        element.request_id==card.request_id)
      console.log("this element befor>>",this.element);
      this.element.request_status=response.name;
      this.element.color=response.color;
      console.log("this element After>>",this.element);
      this.status=response;
      this.hasStatus=true;
    })

    activeModal.componentInstance.DescChange.subscribe(response=>
      {
      console.log("Desc response -> ",response);
      this.element=this.stages[stage_index].tasks.find(element=>
        element.request_id==card.request_id);
      console.log("this element Desc befor>>",this.element);
      this.element.description=response.description;

        

      })
    activeModal.componentInstance.newStatus =card;



  }

}
