import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxEchartsModule } from 'ngx-echarts';
import { Action_card__003_005 } from './components/_#003__cards/_comp__#003_#005__action_card/action_card__003_005.component';
import { Gp__002_004 } from './components/_#002__forms/_comp__#002_#004__gp/gp__002_004.component';
import { Tabs_basic_edit__002_003 } from './components/_#002__forms/_comp__#002_#003__tabs_basic_edit/tabs_basic_edit__002_003.component';
import { Card__003_001 } from './components/_#003__cards/_comp__#003_#001__card/card__003_001.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThemeModule } from '../@theme/theme.module';
import { DataTablesModule } from 'angular-datatables';
import { OwlNativeDateTimeModule, OwlDateTimeModule } from 'ng-pick-datetime';
import { Select2Module } from 'ng2-select2';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NbToastrModule } from '@nebular/theme';
import { NgxInputTagModule } from '@ngx-lite/input-tag';
import { NgxDropzoneModule } from 'ngx-dropzone';



//Import For Smart Table
import { Ng2SmartTableModule } from 'ng2-smart-table';

// import ngx-translate and the http loader
import { TranslateModule} from '@ngx-translate/core';


//Import for Used components
import { RatingComponent } from './rating/rating.component';
import { ActionsComponent } from './actions/actions.component';
import { ModalComponent } from './modal/modal.component';
// import { LayoutModalComponent } from '../pages/layouts/layout-modal/layout-modal/layout-modal.component';
import { SmartTableCellComponent } from './smart-table-cell/smart-table-cell.component';
import { ModuleBtnsComponent } from './module-btns/module-btns.component';
import { AngularFileUploaderModule } from 'angular-file-uploader';
import { FormTemplate2Component } from './form-template2/form-template2.component';
import { PentaBasicFormComponent } from './penta-form-components/penta-basic-form/penta-basic-form.component';
import { PentaNormalBlockComponent } from './blocks/penta-normal-block/penta-normal-block.component';
import { PentaBlockShape2Component } from './blocks/penta-block-shape2/penta-block-shape2.component';
import { NgxLoadingModule} from 'ngx-loading';
import { TableComponent } from './list/table/table.component';
import { PentaStepperFormComponent } from './penta-form-components/penta-stepper-form/penta-stepper-form.component';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { PentaDynmaicFormComponent } from './penta-form-components/penta-dynmaic-form/penta-dynmaic-form.component';
import { DynamicFieldDirective } from './directives/dynamic-field/dynamic-field.directive';
import { InputFieldsComponent } from './components/form_components/Input-fields/input-fields.component';
import { SubmitButtonComponent } from './components/form_components/submit-button/submit-button.component';
import { CheckboxComponent } from './components/form_components/checkbox/checkbox.component';
import { AgmCoreModule } from '@agm/core';
import { RadioButtonComponent } from './components/form_components/radio-button/radio-button.component';
import { BasicFormComponent } from './components/form_components/basic-form/basic-form.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormTabsComponent } from './penta-form-components/form-tabs/form-tabs.component';
import { Tabs__001_001 } from './components/_#001__other/_comp__#001_#001__tabs/tabs__001_001.component';
import { Tabs__002_001 } from './components/_#002__forms/_comp__#002_#001__tabs/tabs__002_001.component';
import { Pagination__001_002 } from './components/_#001__other/_comp__#001_#002__pagination/pagination__001_002.component';
import { FusionChartsModule } from 'angular-fusioncharts';
import * as FusionCharts from 'fusioncharts';

import * as Charts from 'fusioncharts/fusioncharts.charts';
import * as TimeSeries from 'fusioncharts/fusioncharts.timeseries';
import { Statistics__005_001 } from './components/_#005__statistics/_comp__#005_#001__statistics/statistics__005_001.component';
import * as FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import { DateComponent } from './components/form_components/date/date.component';
import { Card__003_002 } from './components/_#003__cards/_comp__#003_#002__card/card__003_002.component';
import { Log__006_001 } from './components/_#006__log/_comp__#006_#001__log/log__006_001.component';
import { Log__006_002 } from './components/_#006__log/_comp__#006_#002__log/log__006_002.component';
import { Card__003_003 } from './components/_#003__cards/_comp__#003_#003__card/card__003_003.component';
import { Card__003_004 } from './components/_#003__cards/_comp__#003_#004__card/card__003_004.component';
import { Progress__007_001 } from './components/_#007__progress/_comp__#007_#001__progress/progress__007_001.component';
import { Tabs_basic__002_002 } from './components/_#002__forms/_comp__#002_#002__tabs_basic/tabs_basic__002_002.component';
import { Uploader__008_001 } from './components/_#008__uploader/_comp__#008_#001__uploader/uploader__008_001.component';
import { Gmap__009_001 } from './components/_#009__maps/_comp__#009_#001__gmap/gmap__009_001.component';
import { Dates__010_001 } from './components/_#010__dates/_comp__#010_#001__date/dates__010_001.component';
import { DateRangePickerModule } from '@syncfusion/ej2-angular-calendars';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { Date__010_002 } from './components/_#010__dates/_comp__#010_#002__date/date__010_002.component';
import { Slider__011_001 } from './components/_011__sliders/_comp__#011_#001__sliders/slider__011_001.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { Card__003_006 } from './components/_#003__cards/_comp__#003_#006__card/card__003_006.component';
import { Pipeline_list__012_001_003 } from './components/_#012__pipeline/_comp__#012_#001__pipeline/_comp__#012_#001__pipeline/_comp__#012_#001_003__pipeline_list/pipeline_list__012_001_003.component';
import { Pipeline_card__012_001_002 } from './components/_#012__pipeline/_comp__#012_#001__pipeline/_comp__#012_#001__pipeline/_comp__#012_#001_002__pipeline_card/pipeline_card__012_001_002.component';
import { Pipeline__012_001_001 } from './components/_#012__pipeline/_comp__#012_#001__pipeline/_comp__#012_#001__pipeline/_comp__#012_#001_001__pipeline/pipeline__012_001_001.component';
import { Pipeline_container__012_001_004 } from './components/_#012__pipeline/_comp__#012_#001__pipeline/_comp__#012_#001__pipeline/_comp__#012_#001_004__pipeline_container/pipeline_container__012_001_004.component';
import { Nav_pill_add__002_005 } from './components/_#002__forms/_comp__#002_#005__nav_pill_add/nav_pill_add__002_005.component';
import { Tree__013_001_001 } from './components/_#013__tree/_comp__#013_#001__tree/tree__013_001_001.component';
import { Chart__004__001_001 } from './components/_#004__charts/_#004__#001__fusion/_comp__#004__#001_#001_chart/chart__004__001_001.component';
import { Chart__004__002_001 } from './components/_#004__charts/_#004__#002__chartjs/_comp__#004__#002_#001_chart/chart__004__002_001.component';
import { ChartModule } from 'angular2-chartjs';
import { Chart_multiple_series__004__001_002 } from './components/_#004__charts/_#004__#001__fusion/_comp__#004__#001_#002_chart_multiple _series/chart_multiple_series__004__001_002.component';
import { Table_basic__014__001_001 } from './components/_#014__tables/_#014__#001_basic/_comp__#014__#001_#001_table_basic/table_basic__014__001_001.component';
import { Key_value__014__002_001 } from './components/_#014__tables/_#014__#002_key_value/_comp__#014__#002_#001_key_value/key_value__014__002_001.component';
import { Nav_pill_edit__002_006 } from './components/_#002__forms/_comp__#002_#006__nav_pill_edit/nav_pill_edit__002_006.component';
import { DirectionModule } from '../direction/direction.module';
import { ltrDir } from '../direction/ltr-dir';
import { rtlDir } from '../direction/rtl-dir';
import { Statistics__005_002 } from './components/_#005__statistics/_comp__#005_#002__statistics/statistics__005_002.component';
import { Statistics__005_003 } from './components/_#005__statistics/_comp__#005_#003__statistics/Statistics__005_003.component';
import { Statistics__005_004 } from './components/_#005__statistics/_comp__#005_#004__statistics/statistics__005_004.component';
import { Table_cards__014__004_001 } from './components/_#014__tables/_#014__#004_cards/_comp__#014__#004_#001_cards/table_cards__014__004_001.component';
import { FixedButtonComponent } from './components/fixed-button/fixed-button.component';
import { ModalFormComponent } from './components/modal-form/modal-form.component';
import { Card__003_007 } from './components/_#003__cards/_comp__#003_#007__card/card__003_007.component';
import { Card__003_008 } from './components/_#003__cards/_comp__#003_#008__card/card__003_008.component';
import { Log__006_003 } from './components/_#006__log/_comp__#006_#003__log/log__006_003.component';
import { Pipeline_modal__012_001_005 } from './components/_#012__pipeline/_comp__#012_#001__pipeline/_comp__#012_#001__pipeline/_comp__#012_#001_005__pipeline_modal/pipeline_modal__012_001_005.component';
import { ViewsComponent } from './components/_#015__views/views/views.component';

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { Calendar_basic__016_001 } from './components/_#016__calendar/_#016__#001__calendar_basic/Calendar_basic__016_001.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { FlatpickrModule } from 'angularx-flatpickr';
import { ScheduleModule, RecurrenceEditorModule, DayService,WeekService,WorkWeekService,MonthService,MonthAgendaService} from '@syncfusion/ej2-angular-schedule';
import { Calendar__016_002 } from './components/_#016__calendar/_#016__#002__calendar/calendar__016_002.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent } from '../@theme/components';
import { Task_list__017_001 } from './components/_#017__tasks/_#017__#001__task_list/task_list__017_001.component';
import { Checkbox__018__001_001 } from './components/_#018__inputs/_#018__#001__checkbox/_comp__#018__#001_#001_checkbox/checkbox__018__001_001.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { MediaInputComponent } from './components/Inputs_types/media-input/media-input.component';
import { DescriptionInputComponent } from './components/Inputs_types/description-input/description-input.component';
import { TextInputComponent } from './components/Inputs_types/text-input/text-input.component';
import { TagsInputComponent } from './components/Inputs_types/tags-input/tags-input.component';
import { MultipleInputComponent } from './components/Inputs_types/multiple-input/multiple-input.component';
import { ImgInputComponent } from './components/Inputs_types/img-input/img-input.component';
import { FilterBtn__019_001 } from './components/_#019__buttons/_comp__#019_#001__filter-btn/filter-btn__019_001.component';
import { ViewKeyValueComponent } from './components/Inputs_types/view-key-value/view-key-value.component';
import { RouterModule } from '@angular/router';
import { Card__003_009Component } from './components/_#003__cards/_comp__#003_#009__card/card__003_009.component';
// import { EditorModule } from '@tinymce/tinymce-angular';



FusionChartsModule.fcRoot(FusionCharts, Charts, TimeSeries,FusionTheme);

@NgModule({
  /*Imports Array*/             
  imports: [
    ThemeModule,
    CommonModule,
    Ng2SmartTableModule,
    DataTablesModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    Select2Module,
    DragDropModule,
    NgxDropzoneModule,
    CarouselModule,
    NgxEchartsModule,
    NgxChartsModule,
    AngularEditorModule,
    // EditorModule,

    //  AngularEditorModule,
    ChartModule,
    NgxDaterangepickerMd.forRoot(),
    RouterModule,

    DirectionModule.forRoot({
      directions : [ltrDir,rtlDir],
      active: 'ltr'
    }),
    NgbModalModule,
    FlatpickrModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    NbToastrModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    NgxInputTagModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    NgxPaginationModule,
    FusionChartsModule,
    DateRangePickerModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAAtVS9PclpxM86CwB_BiSZBY4v_Ydyfc8',
      // apiKey: 'AIzaSyCknR0jhKTIB33f2CLFhBzgp0mj2Tn2q5k',
      libraries: ['places']
    }),
    ScheduleModule, 
    RecurrenceEditorModule,
    NgbModule,
    TranslateModule,


    
    
  ],

   /*Declarations Array*/
   declarations: [
    
    RatingComponent,
    ActionsComponent, 
    ModalComponent, 
    SmartTableCellComponent, 
    ModuleBtnsComponent,
    FormTemplate2Component, 
    PentaBasicFormComponent, 
    PentaNormalBlockComponent, 
    PentaBlockShape2Component,
    PentaStepperFormComponent,
    // PentaTrelloListComponent,
    // PentaTrelloContainerComponent,
    // PentaTrelloCardComponent,
    InputFieldsComponent,
    DynamicFieldDirective,
    PentaDynmaicFormComponent,
    SubmitButtonComponent,
    CheckboxComponent,
    RadioButtonComponent,
    BasicFormComponent,
    Pagination__001_002,
    FormTabsComponent,
    Tabs__001_001,
    Tabs__002_001,
    Card__003_001,
    Chart__004__001_001,
    Statistics__005_001,
    Card__003_002,
    DateComponent,
    Log__006_001,
    Log__006_002,
    Card__003_003,
    Card__003_004,
    Progress__007_001,
    Tabs_basic__002_002,
    Tabs_basic_edit__002_003,
    ModalComponent,
    PentaDynmaicFormComponent,
    Gp__002_004,
    TableComponent,
    Uploader__008_001,
    Gmap__009_001,
    Dates__010_001,
    Date__010_002,
    Action_card__003_005,
    Slider__011_001,
    Card__003_006,
    Pipeline_list__012_001_003,
    Pipeline_card__012_001_002,
    Pipeline__012_001_001,
    Pipeline_container__012_001_004,
    Nav_pill_add__002_005,
    Tree__013_001_001,
    Chart__004__002_001,
    Chart_multiple_series__004__001_002,
    Table_basic__014__001_001,
    Key_value__014__002_001,
    Nav_pill_edit__002_006,
    Statistics__005_002,
    Statistics__005_003,
    Statistics__005_004,
    Table_cards__014__004_001,
    FixedButtonComponent,
    ModalFormComponent,
    
    Card__003_007,    
    Card__003_008,
    Log__006_003,
    Pipeline_modal__012_001_005,
    ViewsComponent,
    Calendar_basic__016_001,
    Calendar__016_002,
    Task_list__017_001,
    Checkbox__018__001_001,
    MediaInputComponent,
    DescriptionInputComponent,
    TextInputComponent,
    TagsInputComponent,
    MultipleInputComponent,
    ImgInputComponent,
    FilterBtn__019_001,
    ViewKeyValueComponent,
    Card__003_009Component
        ],

  /**Exports Array */
  exports:[RatingComponent,
          TranslateModule,
          SmartTableCellComponent, 
          ModuleBtnsComponent,
          NgxInputTagModule, 
          AngularFileUploaderModule,
          FormTemplate2Component,
          PentaBasicFormComponent,
          PentaNormalBlockComponent,
          PentaBlockShape2Component,
          PentaStepperFormComponent,
          // PentaTrelloListComponent,
          // PentaTrelloContainerComponent,
          // PentaTrelloCardComponent,
          InputFieldsComponent,
          PentaDynmaicFormComponent,
          SubmitButtonComponent,
          CheckboxComponent,
          DateComponent,
          RadioButtonComponent,
          BasicFormComponent,
          FormTabsComponent,
          Tabs__001_001,
          Tabs__002_001,
          Card__003_001,
          Card__003_002,
          Chart__004__001_001,
          Statistics__005_001,
          Log__006_001,
          Log__006_002,
          Card__003_003,
          Card__003_004,
          Progress__007_001,
          Tabs_basic__002_002,
          Tabs_basic_edit__002_003,
          ModalComponent,
          PentaDynmaicFormComponent,
          Gp__002_004,
          TableComponent,
          ActionsComponent,
          RatingComponent,
          Uploader__008_001,
          Gmap__009_001,
          Dates__010_001,
          Date__010_002,
          Action_card__003_005,
          Slider__011_001,
          Card__003_006,
          Pipeline_list__012_001_003,
          Pipeline_card__012_001_002,
          Pipeline__012_001_001,
          Pipeline_container__012_001_004,
          Nav_pill_add__002_005,
          Tree__013_001_001,
          Chart__004__002_001,
          Chart_multiple_series__004__001_002,
          Table_basic__014__001_001,
          Key_value__014__002_001,
          Nav_pill_edit__002_006,
          Statistics__005_002,
          Statistics__005_003,
          Statistics__005_004,
          Table_cards__014__004_001,
          Card__003_007,
          Card__003_008,
          Log__006_003,
          Pipeline_modal__012_001_005,
          Calendar_basic__016_001,
          Calendar__016_002,
          Task_list__017_001,
          Checkbox__018__001_001,
          Pagination__001_002,
          
          MediaInputComponent,
          DescriptionInputComponent,
          TextInputComponent,
          TagsInputComponent,
          MultipleInputComponent,
          ImgInputComponent,
          FilterBtn__019_001,
          ViewKeyValueComponent,
          Card__003_009Component

      ],
  
  /*Entry Components Array*/
  entryComponents:[
                  SmartTableCellComponent,
                  // PentaTrelloContainerComponent,
                  InputFieldsComponent,
                  CheckboxComponent,
                  RadioButtonComponent,
                  SubmitButtonComponent,
                  DateComponent,
                  Card__003_001,
                  Card__003_002,
                  Statistics__005_001,
                  Log__006_001,
                  Log__006_002,
                  Card__003_003,
                  Card__003_004,
                  Progress__007_001,
                  Tabs_basic__002_002,
                  Tabs_basic_edit__002_003,
                  ModalComponent,
                  PentaDynmaicFormComponent,
                  Chart__004__001_001,
                  Gp__002_004,
                  ModuleBtnsComponent,
                  TableComponent,
                  ActionsComponent,
                  RatingComponent,
                  Uploader__008_001,
                  Gmap__009_001,
                  Dates__010_001,
                  Date__010_002,
                  Action_card__003_005,
                  Slider__011_001,
                  Card__003_006,
                  Pipeline_list__012_001_003,
                  Pipeline_card__012_001_002,
                  Pipeline__012_001_001,
                  Pipeline_container__012_001_004,
                  Nav_pill_add__002_005,
                  Tree__013_001_001,
                  Chart__004__002_001,
                  Chart_multiple_series__004__001_002,
                  Table_basic__014__001_001,
                  Key_value__014__002_001,
                  Nav_pill_edit__002_006,
                  Statistics__005_002,
                  Statistics__005_003,
                  Statistics__005_004,
                  Table_cards__014__004_001,
                  Card__003_007,
                  Card__003_008,
                  Log__006_003,
                  Pipeline_modal__012_001_005,
                  Calendar_basic__016_001,
                  Calendar__016_002,
                  Task_list__017_001,
                  Checkbox__018__001_001,
                  Pagination__001_002,
                  MediaInputComponent,
                  DescriptionInputComponent,
                  TextInputComponent,
                  TagsInputComponent,
                  MultipleInputComponent,
                  ImgInputComponent,
                  ViewKeyValueComponent,
                  FilterBtn__019_001
                  // BasicFormComponent,
                ],
                providers:[DayService,WeekService,WorkWeekService,MonthService,MonthAgendaService]

})
export class SharedModule { }
/**
 * this module the shared  to all layouts compontentdd
 * 
 */