import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConstData } from '../../../consts/ApiConstData';
import { BASE_API_URL } from '../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  baseURL = BASE_API_URL;
  notificationsList;
  constructor(private httpClient: HttpClient,
              private apiConstants: ApiConstData,
    ) { }
  /**
   * This Function try to get Ntification Items
   */
  getNotificationsList(){
      return this.notificationsList = [
        {
          "icon_class": "",
          "title": "3 new requests",
          "target_type":'layout',
          "target_layout_id":2,
          "page_id":1,
          "datetime": "2017-06-11 12:03:33",
          "duration": "1 min ago"
        },
        {
          "icon_class": "",
          "title": "2 new orders",
          "target_type":'url',
          "target_layout_id":'',
          "page_id":'',
          "target_url": "http://www.example.com",
          "datetime": "2017-06-11 12:03:33",
          "duration": "1 day ago"
        },
        {
          "icon_class": "",
          "title": "10 new boxes",
          "url": "http://www.example.com",
          "target_type":'layout',
          "target_layout_id":3,
          "page_id":1,
          "datetime": "2017-06-11 12:03:33",
          "duration": "just now"
        }
      ]
  }

  getNotification() {
    return this.httpClient.post(`${this.baseURL}/notifications`,this.apiConstants.getHeaderandBody().apiBody,{headers: this.apiConstants.getHeaderandBody().apiHeaders})
  }
  

  readNotification(notificationID)
  {
    return this.httpClient.post(`${this.baseURL}/notifications/update`,{id:notificationID},{headers: this.apiConstants.getHeaderandBody().apiHeaders})
  }

  showallNotification(){
    let body ={
      module_id:513,
      template_id:1544,
      ...this.apiConstants.getHeaderandBody().apiBody
    }
    return this.httpClient.post(`${this.baseURL}/notifications/list`,
    {
      module_id:513,
      template_id:1544,
      ...this.apiConstants.getHeaderandBody().apiBody
    }
    ,{headers: this.apiConstants.getHeaderandBody().apiHeaders})

  }


}

