import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'form-tabs',
  templateUrl: './form-tabs.component.html',
  styleUrls: ['./form-tabs.component.scss']
})
export class FormTabsComponent implements OnInit {
  regConfig:any;
  testForm:FormGroup;
  languages;
  constructor(private formBuilder: FormBuilder) { 
    this.testForm = formBuilder.group({
      'name':['',],
      'desc':['',],
      'notes':[''],
      'address':[''],
      'telephone':[''],
      'code':[''],
    })
  }


  submit(formValue){

    console.log(formValue)
  }
  ngOnInit() {
   
    this.languages = {
      'lang':[{'name':'English','index':'en','flag':'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRgAhSxtc0Y42tc3Cp-LqBhoddB05I0Eiqt09ficpdGtFfi82BR'},{'name':'French','index':'fr','flag':'https://upload.wikimedia.org/wikipedia/en/thumb/c/c3/Flag_of_France.svg/1200px-Flag_of_France.svg.png'},{'name':'Arabic','index':'ar','flag':'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTZSlBJpzU0K4BxxDmVE4GN1p-ioxAVZQvnb2CDrrnlFj4Ms9yT'}]
      ,'form':{
        'formName':"",
        'fields':[
          {
            type: "input",
            label: "Username",
            inputType: "text",
            name: "name",
            value:'Hager mohammed',
            validations: [
              {
                name: "required",
                msg: "this Field Required",
                validator_value: ""
            },
            {
                name: "minlength",
                msg: "minlength For This Field is 5",
                validator_value: "5"
            },
            {
                name: "maxlength",
                msg: "maxlength for this field is 10",
                validator_value: "10"
            },
            ]
          },
          {
            type:"checkbox",
            label:"Female",
            name:"female",
            value:true,
            validations:[],
          },
          {
            type:"button",
            label:"Submit",
            value:'',
            validations:[],
          },
        ]
      }
    }

  }
  
  getLangName(langIndex){
    console.log("langName => ",langIndex);
    $(`#language_section`).find('*').removeClass('active');
    $(`#${langIndex}`).addClass('active');
  }

  submit_form(event){
    console.log("/*/*/*/*/*")
    console.log("submit_form=>",event);
  }

}
