import { Component, OnInit } from '@angular/core';
import { ApiConstData } from '../../../../../../consts/ApiConstData';
import { HttpClient } from '@angular/common/http';
import { GlobalFunctions } from '../../../../../../consts/global-functions';
import { GlobalService } from '../../../../../services/global/global.service';
import { Configurations } from '../../../../../../consts/configurations';

@Component({
  selector: 'chart_multiple_series__004__001_002',
  templateUrl: './chart_multiple_series__004__001_002.component.html',
  styleUrls: ['./chart_multiple_series__004__001_002.component.scss']
})
export class Chart_multiple_series__004__001_002 implements OnInit {
  apiLink;
  apiParameters;
  children;
  moduleID;
  chartData;
  dataSource;
  width = "100%";
  height = 400;
  type;
 
  dataFormat = "json";
  constructor(private httpClient: HttpClient,
              private apiConst:ApiConstData,
              public config: Configurations,
              private GlobalFunctions: GlobalFunctions,
              private globalVariables: GlobalService,      
    ) { 
     
    }
    ngOnChange(){
    
    }
  ngOnInit() {
    this.httpClient.post<any>(`${this.apiLink}`,this.apiParameters,{headers:this.apiConst.getHeaderandBody().apiHeaders}).subscribe(response=>{
      console.log("response multichart =>",response);
      this.chartData = response;
      this.dataSource = response;
      this.type = response['chart'].type
      console.log("chartTYpe in series component=> ",this.type);
      console.log("Pie Chart Data json=> ",this.dataSource);

      this.globalVariables.setCompLoadedNum();
    },error=>{
      this.globalVariables.setCompLoadedNum();
      this.GlobalFunctions.showToastr('danger', 'Error', this.config.apiResponseMsg.danger.msg, this.config.apiResponseMsg.danger.icon)
    });
    
    console.log("chart_mutiple_004_001_002_apiLink => ",this.apiLink);
    console.log("chart_mutiple_004_001_002_apiParameters=> ",this.apiParameters);
    }

}
