import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import 'rxjs/add/operator/map'
import { PaginationService } from '../../../../services/pagination/pagination.service';



@Component({
  selector: 'pagination__001_002',
  templateUrl: './pagination__001_002.component.html',
  styleUrls: ['./pagination__001_002.component.scss']
})
export class Pagination__001_002 implements OnInit {
  @Input() allItems: number;
  @Input() activePage: number;
  @Output() activePageNum: EventEmitter<any> = new EventEmitter<any>();

  // pager object
  pager: any = {};
  // paged items
  pagedItems: any[];

  constructor(private pagerService: PaginationService) { }

  ngOnInit() {
    console.log("activePage => ", this.activePage);
    console.log("alItems=> ", this.allItems);
    this.setPage(this.activePage);
  }

  setPage(page: number) {
    console.log("pageNumber => ", page);
    this.activePageNum.emit(page);
    // get pager object from service
    this.pager = this.pagerService.getPager(this.allItems, page);
    // get current page of items
    // this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

}
