import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dates__010_001',
  templateUrl: './dates__010_001.component.html',
  styleUrls: ['./dates__010_001.component.scss']
})
export class Dates__010_001 implements OnInit {
  testForm: FormGroup;

  public start: Date = new Date ("10/07/2017"); 
  public end: Date = new Date ("11/25/2017");
  constructor(public fb: FormBuilder) { 
   
  }

  ngOnInit() {
  }
  submit(formValue) {
    console.log(formValue)
  }

}
