import { Component, OnInit, Input, ComponentFactoryResolver, ViewContainerRef, ViewChild } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { Angular5Csv } from '../../../../../node_modules/angular5-csv/dist/Angular5-csv'
import { Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { HttpClient } from '@angular/common/http';

import { ApiConstData } from '../../../../consts/ApiConstData';
import { Configurations } from '../../../../consts/configurations';
import { GlobalFunctions } from '../../../../consts/global-functions';
import { ActionsComponent } from '../../actions/actions.component';
import { SmartTableCellComponent } from '../../smart-table-cell/smart-table-cell.component';
import { SERVER_URL, BASE_API_URL } from '../../../../environments/environment';
import { RatingComponent } from '../../rating/rating.component';
import { ListService } from '../../../services/list/list.service';
import { GlobalService } from '../../../services/global/global.service';
import { Pagination__001_002 } from '../../components/_#001__other/_comp__#001_#002__pagination/pagination__001_002.component';
import { ActiveComponentsService } from '../../../services/active-components/active-components.service';

@Component({
  selector: 'smart_table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
  @ViewChild('pagination',{read:ViewContainerRef}) pagination: ViewContainerRef;

  serverURL: string = "";
  baseAPIUrl = "";
  allItems: any;
  allListItems;
  headerList = [];
  contentList = [];
  basicData = [];
  newBasicData = [];
  bulkActions = [];
  basicBtn = [];
  headerExcel = [];
  newHeader = {};
  moduleID;
  selectedRows;
  myFile;
  test_active: boolean = true;
  settings = {};
  test;
 
  apiLink: string;
  apiParameters;
  apiResponseMsg: any = this.config.apiResponseMsg;
  //the number of raw that will display in table
  perPage: number = 10;
  activePage: number = 1;
  countDrawingPager = 0;

  source: LocalDataSource = new LocalDataSource();

  constructor(
              private httpClient: HttpClient,
              private router: Router,
              public toastr: NbToastrService,
              private resolver: ComponentFactoryResolver,

              public apiConstData: ApiConstData,
              public config: Configurations,
              public GlobalFunctions: GlobalFunctions,
              private listService: ListService,
              private globalVariables: GlobalService,
              private activeComponents: ActiveComponentsService
    ) {
    // this.source.load(this.newBasicData);
  }

  ngOnInit() {
    console.log("tableeeee")
    console.log("*/*/*/*/*api Link /**/*/* => ", this.apiLink);
    console.log("*/*/*/*/Module ID /**/*/* => ", this.moduleID);
    console.log("*/*/*/*/apiParameters/**/*/* => ", this.apiParameters);
    this.getListItem();
  }

  /**
   * this function try to 
   * @param headerList 
   * @param contentData 
   */
  drawTable(headerList: Array<any>,contentData: Array<any>){
    console.log("//*/*/*/* da5al draw el table/**/*/**");
    console.log("content data => ", contentData);
    headerList.forEach(element => {
        var r;
        let key = element.name;
        this.headerExcel.push(element.name)
        if (element.name == 'rate') {
          this.newHeader[`${key}`] = {
            title: element.label,
            type: 'custom',
            renderComponent: RatingComponent,
            valuePrepareFunction: (rate) => {
              if (rate.value) {
                r = rate.value
                this.globalVariables.fromDataTable = true;
                this.globalVariables.rate = r
              }
            },

          };
        }
        else if (element.name == 'image' || element.name == 'photo') {
          this.newHeader[`${key}`] = {
            title: element.label,
            type: 'html',
            valuePrepareFunction: (image) => {
              return (image ? `<img src= '${image.value}' width="50"/>` : `<div>No Image</div>`)

              // valuePrepareFunction: (image) => { return image.value && image.value.includes('.') ? `<img (click)="test()" src="${image.value}" width="50" />` : '<div>No Image</div>'
            },
          }
        }
        else if (element.name == 'active') {
          this.newHeader[`${key}`] = {
            title: element.label,
            type: 'html',
            valuePrepareFunction: (active) => { return (active.value == 1 ? `<i class="fa fa-check " style="color:red"></i>` : '<i class="fa fa-times"></i>') },
          }
        }

        else if (element.name == 'actions') {
          this.newHeader[`${key}`] = {
            title: element.label,
            type: 'custom',
            renderComponent: ActionsComponent,
            valuePrepareFunction: (actions, id) => {
              if (actions.value) {
                this.globalVariables.actionsBtn = actions.value;
                this.globalVariables.rowID = id;
                this.globalVariables.apiParameters = this.apiParameters;
              }
            },
          };
        }

        else {
          this.newHeader[`${key}`] = {
            title: element.label,
            type: 'custom',
            renderComponent: SmartTableCellComponent,
            valuePrepareFunction: (data) => {
              // console.log("settings /*/*/*/* => ",data.settings)
              this.globalVariables.cellSettings = data;
            }
          };
        }

      })

    contentData.forEach(element => {
        var data = {}
        element.forEach(element1 => {
          // console.log("element basic data => ",element1)
          let key = element1.settings.title;
          data[`${key}`] = element1;
        });
        this.newBasicData.push(data)
      })
      console.log("new basic data source in draw table function =>",this.newBasicData);
     
     
  }
  /**
   * this function draws the pagination of smart table to handel server side loading data
   * @param itemNum =>the total number of data that will be locate in table
   * @param activePage => the page number in the pager
   */
  drawPager(itemNum,activePage): void{
    console.log("pagination (itemNum)",itemNum);
    console.log("pagination (activePage)",activePage);
    var pager;
    const factory = this.resolver.resolveComponentFactory(Pagination__001_002);
    pager = this.pagination.createComponent(factory);
    pager.instance.allItems = itemNum;
    pager.instance.activePage = activePage;
    pager.instance.activePageNum.subscribe(result=>{
      this.activePage = result;
      this.newBasicData = []
      this.source.empty();
      this.getListItem();
      setTimeout(()=>{
    this.globalVariables.setCompLoadedNum();
    console.log("compNumTable=> ",this.globalVariables.compLoadedNum)
  },1000)
    })
  }

  // setTimeout(()=>{
  //   this.globalVariables.setCompLoadedNum();
  //   console.log("compNumTable=> ",this.globalVariables.compLoadedNum)
  // },10000)
  getListItem() {
    this.settings = {
      pager:{
          display:false,
      },
      hideSubHeader: true,
      selectMode: 'multi',
      actions: {
        add: false,
        edit: false,
        delete: false,
        class: "action-column",
      },
      // columns: this.newHeader
    };

    this.listService.getListModule(this.apiParameters, this.apiLink,this.perPage,this.activePage,).subscribe(response => {
      //to check if data returned from api or not
      console.log("list response=> ",response)
      this.allListItems = response;
      this.basicData = this.allListItems.content.basic_data;
      this.basicBtn = this.allListItems.content.basic_button;
      this.headerList = this.allListItems.content.header;
      this.bulkActions = this.allListItems.content.bulk_actions;
      this.allItems = this.allListItems.summary.count.total_rows;
     
      console.log("basic Data => ",this.basicData)

      if (this.basicData == undefined) {
        this.GlobalFunctions.showToastr('warning', 'Warning', this.apiResponseMsg.warning.msg, this.apiResponseMsg.warning.icon)
        return;
      }
      //draw table
      this.drawTable(this.allListItems.content.header,this.allListItems.content.basic_data)
      if(this.countDrawingPager == 0){   
        console.log("countDrawing pager => da5al gwa")   
        console.log("total rows=> ",response.summary.count.total_rows);
        console.log("active page=> ",this.activePage)
        console.log("all ListItem=> ",this.allListItems)
        this.drawPager(this.allListItems.summary.count.total_rows,this.activePage)
      }

      this.countDrawingPager += 1;
      this.settings = {
        hideSubHeader: true,
        selectMode: 'multi',

        add: {
          addButtonContent: '<i class="nb-plus"></i>',
          createButtonContent: '<i class="nb-checkmark"></i>',
          cancelButtonContent: '<i class="nb-close"></i>',
        },
        edit: {
          editButtonContent: '<i class="nb-edit"></i>',
          saveButtonContent: '<i class="nb-checkmark"></i>',
          cancelButtonContent: '<i class="nb-close"></i>',
        },
        delete: {
          deleteButtonContent: '<i class="nb-trash"></i>',
          confirmDelete: true,
        },
        actions: {
          add: false,
          edit: false,
          delete: false,
          class: "action-column",
        },
        columns: this.newHeader
      };
      console.log("/*/***/*/*/*/*/*/*/*/*/*/*/*/*/*/*")
      console.log("basic Data => ",this.basicData)
      console.log("/*/***/*/*/*/*/*/*/*/*/*/*/*/*/*/*")
      console.log("new basic data => ",this.newBasicData);
      this.source.load(this.newBasicData);
    }, error => {
        this.globalVariables.setCompLoadedNum();
        this.GlobalFunctions.showToastr('danger', 'Error', this.apiResponseMsg.danger.msg, this.apiResponseMsg.danger.icon)
    })
  }


  bulkActionfunc(item) {
    let target = item.target_type
    if (this.selectedRows) {
      var selectedID = [];
      console.log("selected row => ",this.selectedRows)

      this.selectedRows.forEach(element => {
        console.log("element =>", element)
        selectedID.push(element.id.value)
      })
      console.log("selected row =>", selectedID);

      //-=============================-Incase target_type == 'api'-==================
      if (target == 'api') {
        let apiURL = item.target_url;
        console.log("delete=> ", apiURL)
        let body = { ...{ "ids": selectedID }, ...this.apiConstData.getHeaderandBody().apiBody }
        console.log(body)
        this.httpClient.request('post', `${this.baseAPIUrl}${apiURL}`, { body: body, headers: this.apiConstData.getHeaderandBody().apiHeaders }).subscribe(res => {
          console.log(res);
          this.router.navigateByUrl('/RefrshComponent', { skipLocationChange: false }).then(() =>
            this.router.navigate(['/pages/layouts/layout1'], { queryParams: { moduleID: this.moduleID, componentsList:this.activeComponents.getActiveComponents()} }));
        }, error => {
          console.log(error)
          this.GlobalFunctions.showToastr('danger', 'Error', this.apiResponseMsg.danger.msg, this.apiResponseMsg.danger.icon)
        })
      }

      if (target == 'export-excel') {
        var options = {
          fieldSeparator: ',',
          quoteStrings: '"',
          showLabels: true,
          showTitle: false,
          title: 'Your title',
          useBom: true,
          noDownload: false,
          headers: this.headerExcel,
          nullToEmptyString: true,
        };

        this.myFile = new Angular5Csv(this.selectedRows, 'Report', options);

      }

      if (target == 'print') {
        var key;
        var tablePrint = `
                    <table border="1" cellpadding="3" id="printTable">
                    <tbody>
                    <tr>`
        for (var i = 0; i <= this.headerExcel.length; i++) {
          tablePrint += `<th>${this.headerExcel[i]}</th>`
          key = this.headerExcel[i];
        }
        tablePrint += `</tr>`

        for (var i = 0; i <= this.selectedRows.length; i++) {
          tablePrint += `<tr>`
          // for(var j =0 ;j<= this.selectedRows[i].length ; j++){
          //  tablePrint +=  `<td>${this.selectedRows[j].name}</td>`
          // }

        }

        // console.log(this.selectedRows[0])
        // console.log("print")
        // console.log(this.selectedRows)

        let newWin = window.open("");
        newWin.document.write(tablePrint);
        newWin.print();
        newWin.close();
      }
    }//-==========End if (if there is selected rows)
    else {
      alert("please select rows ")
    }
  }

  basicActionFunc(targetType) {
    alert(targetType)
  }

  onRowSelect(event) {
    this.selectedRows = event.selected
    console.log("evet selected=> ", event.selected)
  }

}
