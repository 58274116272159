import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BASE_API_URL } from '../../../environments/environment';
import { ApiConstData } from '../../../consts/ApiConstData';

@Injectable({
  providedIn: 'root'
})
export class MediaService {
  baseURL = BASE_API_URL;
  constructor(private httpClient: HttpClient,
              public apiConstData: ApiConstData,
    ) { }

  deleteMedia(mediaObj){
    let body = {...mediaObj,...this.apiConstData.getHeaderandBody().apiBody}
    console.log("media body = > ",body);
    console.log(mediaObj);
    return this.httpClient.post<any>(`${this.baseURL}/cms_media/destroy`,body,{headers:this.apiConstData.getHeaderandBody().apiHeaders})
  }
}
