import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BASE_API_URL } from '../../../environments/environment';
import { ApiConstData } from '../../../consts/ApiConstData';

@Injectable({
    providedIn: 'root'
})
export class ListService {
    api = "";
    headers: HttpHeaders;
    normalTableData;
    constructor(private httpClient: HttpClient,
        private apiConstData: ApiConstData,
    ) {
    }


    getListModule(apiParameters, apiStatic, perPage,activePage) {
        let specificBody = {
            "pagination": perPage,
            "page": activePage,
        }
        let body = { ...specificBody, ...this.apiConstData.getHeaderandBody().apiBody,...apiParameters }
        return this.httpClient.post<any>(`${this.api}${apiStatic}`, body, { headers: this.apiConstData.getHeaderandBody().apiHeaders })
    }
    //List object
    public listItem =

        {
            "status": {
                "code": 200,
                "message": "Operation completed successfully",
                "error_details": {
                    "validation": [],
                    "other": []
                }
            },
            "content": {
                "header": [
                    {
                        "label": "ID",
                        "name": "id",
                        "id": 1,
                        "type": "text"
                    },
                    {
                        "label": "Product Name",
                        "name": "product-name",
                        "id": 2,
                        "type": "text"
                    },
                    {
                        "label": "Quantity",
                        "name": "quantity",
                        "id": 3,
                        "type": "text"
                    },
                    {
                        "label": "Price",
                        "name": "price",
                        "id": 4,
                        "type": "text"
                    },
                    {
                        "label": "Total Price",
                        "name": "total-price",
                        "id": 4,
                        "type": "text"
                    },
                ],
                "basic_data": [

                    [
                        {
                            "value": 34,
                            "settings": {
                                "title": "id",
                                "target_type": "",
                                "target_layout_id": 4,
                                "page_id": 5,
                                "target_url": "",
                                "target_content": "",
                                "content_id": "",
                                "target_action_id": ""
                            }
                        },
                        {
                            "value": "sama",
                            "settings": {
                                "title": "username",
                                "target_type": "",
                                "target_layout_id": 4,
                                "page_id": 5,
                                "target_url": "",
                                "target_content": "",
                                "content_id": "",
                                "target_action_id": ""
                            }
                        },

                        {
                            "value": [
                                {
                                    "value": 76,
                                    "settings": {
                                        "title": "item_id",
                                        "target_type": "",
                                        "target_layout_id": 4,
                                        "page_id": 5,
                                        "target_url": "",
                                        "target_content": "",
                                        "content_id": "",
                                        "target_action_id": ""
                                    }
                                },
                                {
                                    "value": 20,
                                    "settings": {
                                        "title": "total_cost",
                                        "target_type": "layout",
                                        "target_layout_id": 4,
                                        "page_id": 5,
                                        "target_url": "",
                                        "target_content": "",
                                        "content_id": "",
                                        "target_action_id": ""
                                    }
                                },
                                {
                                    "value": "4",
                                    "settings": {
                                        "title": "qty",
                                        "target_type": "",
                                        "target_layout_id": 4,
                                        "page_id": 5,
                                        "target_url": "",
                                        "target_content": "",
                                        "content_id": "",
                                        "target_action_id": ""
                                    }
                                },
                                {
                                    "value": 5,
                                    "settings": {
                                        "title": "product_cost",
                                        "target_type": "layout",
                                        "target_layout_id": 4,
                                        "page_id": 5,
                                        "target_url": "",
                                        "target_content": "",
                                        "content_id": "",
                                        "target_action_id": ""
                                    }
                                },
                            ],
                            "settings": {
                                "title": "orders",
                                "target_type": "",
                                "target_layout_id": 4,
                                "page_id": 5,
                                "target_url": "",
                                "target_content": "",
                                "content_id": "",
                                "target_action_id": ""
                            }
                        },
                    ],


                    [
                        {
                            "value": 34,
                            "settings": {
                                "title": "id",
                                "target_type": "",
                                "target_layout_id": 4,
                                "page_id": 5,
                                "target_url": "",
                                "target_content": "",
                                "content_id": "",
                                "target_action_id": ""
                            }
                        },
                        {
                            "value": "Toqa",
                            "settings": {
                                "title": "username",
                                "target_type": "",
                                "target_layout_id": 4,
                                "page_id": 5,
                                "target_url": "",
                                "target_content": "",
                                "content_id": "",
                                "target_action_id": ""
                            }
                        },

                        {
                            "value": [
                                {
                                    "value": 76,
                                    "settings": {
                                        "title": "item_id",
                                        "target_type": "",
                                        "target_layout_id": 4,
                                        "page_id": 5,
                                        "target_url": "",
                                        "target_content": "",
                                        "content_id": "",
                                        "target_action_id": ""
                                    }
                                },
                                {
                                    "value": 20,
                                    "settings": {
                                        "title": "total_cost",
                                        "target_type": "layout",
                                        "target_layout_id": 4,
                                        "page_id": 5,
                                        "target_url": "",
                                        "target_content": "",
                                        "content_id": "",
                                        "target_action_id": ""
                                    }
                                },
                                {
                                    "value": "4",
                                    "settings": {
                                        "title": "qty",
                                        "target_type": "",
                                        "target_layout_id": 4,
                                        "page_id": 5,
                                        "target_url": "",
                                        "target_content": "",
                                        "content_id": "",
                                        "target_action_id": ""
                                    }
                                },
                                {
                                    "value": 5,
                                    "settings": {
                                        "title": "product_cost",
                                        "target_type": "layout",
                                        "target_layout_id": 4,
                                        "page_id": 5,
                                        "target_url": "",
                                        "target_content": "",
                                        "content_id": "",
                                        "target_action_id": ""
                                    }
                                },
                            ],
                            "settings": {
                                "title": "orders",
                                "target_type": "",
                                "target_layout_id": 4,
                                "page_id": 5,
                                "target_url": "",
                                "target_content": "",
                                "content_id": "",
                                "target_action_id": ""
                            }
                        },
                    ],

                ],
                "basic_button": [
                    {
                        "title": "view",
                        "target_action_id": "1",
                        "target_type": "popup",
                        "target_content": "block",
                        "content_id": 18,
                        "target_layout_id": "",
                        "target_url": "\/\/show",
                        "icon": "fa fa-eye",
                        "counter": 15
                    },
                    {
                        "title": "edit",
                        "target_action_id": "1",
                        "target_type": "popup",
                        "target_content": "page",
                        "content_id": 15,
                        "target_layout_id": "2",
                        "target_url": "\/\/update",
                        "icon": "fa fa-pencil",
                        "counter": 66
                    },
                    {
                        "title": "delete",
                        "target_action_id": "1",
                        "target_type": "ajax",
                        "target_content": "block",
                        "content_id": 14,
                        "target_layout_id": "2",
                        "target_url": "\/\/delete",
                        "icon": "fa fa-trash-o",
                        "counter": null
                    },
                    {
                        "title": "retweet",
                        "target_action_id": "1",
                        "target_type": "ajax",
                        "target_content": "block",
                        "content_id": 14,
                        "target_layout_id": "2",
                        "target_url": "http =>\/\/",
                        "icon": "fa fa-retweet",
                        "counter": null
                    }
                ],
                "bulk_actions": [
                    {
                        "title": "Delete Selected",
                        "target_action_id": "1",
                        "target_type": "api",
                        "target_content": "block",
                        "content_id": 12,
                        "target_layout_id": "2",
                        "target_url": "\/\/destroyall",
                        "icon": ""
                    },
                    {
                        "title": "Activate Selected",
                        "target_action_id": "1",
                        "target_type": "ajax",
                        "target_content": "page",
                        "content_id": 1,
                        "target_layout_id": "2",
                        "target_url": "http =>\/\/",
                        "icon": ""
                    },
                    {
                        "title": "Export Excel",
                        "target_action_id": "1",
                        "target_type": "ajax",
                        "target_content": "page",
                        "content_id": 1,
                        "target_layout_id": "2",
                        "target_url": "http =>\/\/",
                        "icon": ""
                    },
                    {
                        "title": "Print",
                        "target_action_id": "1",
                        "target_type": "ajax",
                        "target_content": "page",
                        "content_id": 1,
                        "target_layout_id": "2",
                        "target_url": "http =>\/\/",
                        "icon": ""
                    }
                ],
                "statistics": [
                    {
                        "label": "Messages",
                        "value": "155",
                        "percentage": "40",
                        "note": "38% more than last month",
                        "icon": "fa fa-arrow-up"
                    },
                    {
                        "label": "Notifications",
                        "value": "1410",
                        "percentage": "50",
                        "note": "3% more than last month",
                        "icon": "fa fa-arrow-up"
                    }
                ]
            },
            "summary": {
                "filtered_by": [
                    "filter 1",
                    "filter 2",
                    "filter 3"
                ],
                "count": {
                    "displayed_rows": 39,
                    "total_rows": 1200
                },
                "limit": 50,
                "pagination": 2

            }
        }


}
