import { Injectable, Inject,EventEmitter } from '@angular/core';
import { DIRECTIONS, Direction, ACTIVE_DIRECTION } from './symbols';

@Injectable({
  providedIn: 'root'
})
export class DirectionService {
  directionChange = new EventEmitter<Direction>();

  constructor(
    @Inject(DIRECTIONS) public directions: Direction[],
    @Inject(ACTIVE_DIRECTION) public direction: string,
  ) { }

  getDirection(name: string) {
    const direction = this.directions.find(dir=> dir.name === name);
    if(!direction) {
      throw new Error(`this direction not fount`)
    }
    return direction;
  }

  getActiveDirection() {
    return this.getDirection(this.direction);
  }

  getProperty(propName: string) {
      return this.getActiveDirection().properties[propName]
  }
  setDirection(name: string) {
    this.direction = name;
    this.directionChange.emit(this.getActiveDirection());
  }

  updateDirection(name: string, properties: { [key: string]: string; }) {
      const direction = this.getDirection(name);
      direction.properties = {
        ...direction.properties,
        ...properties
      };
      if(name == this.direction){
        this.directionChange.emit(direction)
      }
  }
}
