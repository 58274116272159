import { Directive, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Direction } from './symbols';
import { DirectionService } from './direction.service';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[dir]'
})
export class DirectionDirective implements OnInit, OnDestroy{
  private _destroy$ = new Subject();
  constructor(
              private _elementRef: ElementRef,
              private _directionService: DirectionService
  ) { }

  ngOnInit() {
    const active = this._directionService.getActiveDirection();
    if(active) {
      this.updateDirection(active);
    }
    this._directionService.directionChange.pipe(takeUntil(this._destroy$))
    .subscribe((direction: Direction) => this.updateDirection(direction))
  }
  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
 updateDirection (direction: Direction) {
   for (const key in direction.properties) {
    this._elementRef.nativeElement.style.setProperty(key, direction.properties[key]);
   }

  //  for(const name of this._directionService.direction)
 }
}
