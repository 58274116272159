import { TestServiceService } from './../../../../services/test_service/test-service.service';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConstData } from '../../../../../consts/ApiConstData';
import { GlobalFunctions } from '../../../../../consts/global-functions';
import { Configurations } from '../../../../../consts/configurations';
import { GlobalService } from '../../../../services/global/global.service';

@Component({
  selector: 'statistics__005_001',
  templateUrl: './statistics__005_001.component.html',
  styleUrls: ['./statistics__005_001.component.scss']
})
export class Statistics__005_001 implements OnInit {
  apiLink;
  apiParameters;
  moduleID;
  statisticsArray = [];
  constructor(private testService: TestServiceService,
              private httpClient: HttpClient,
              private apiConstData:ApiConstData,
              private GlobalFunctions: GlobalFunctions,
              public config: Configurations,
              private globalVariables: GlobalService,
      ) { }

  ngOnInit() {
    console.log("Statistics__005_001_oninit")
    console.log("Statistics__005_001_(apiLink)=> ",this.apiLink);
    console.log("Statistics__005_001_(parameters)=> ",this.apiParameters);
    console.log("Statistics__005_001_(moduleID)=> ",this.moduleID);
    // this.statisticsArray = this.testService.statistics_structure;

    
    this.httpClient.post<any>(`${this.apiLink}`,this.apiParameters,{headers:this.apiConstData.getHeaderandBody().apiHeaders}).subscribe(response=>{
      console.log("Statistics__005_001_response => ",response);

      this.statisticsArray = response.statistics_structure;
      //(testing)
      // this.statisticsArray = this.testService.statistics_structure;
      //(testing)
      console.log("statistics_structure=> ",this.statisticsArray)
      this.globalVariables.setCompLoadedNum();
      console.log("Statistics__005_001_CompLoadedNum=> ",this.globalVariables.compLoadedNum);

    },error=>{

      this.globalVariables.setCompLoadedNum();
      console.log("Statistics__005_001_CompLoadedNum=> ",this.globalVariables.compLoadedNum);
      this.GlobalFunctions.showToastr('danger', 'Error', this.config.apiResponseMsg.danger.msg, this.config.apiResponseMsg.danger.icon)
    
    })
  
    // this.statisticsArray = this.testService.statistics_structure;
  }

}
