import { InjectionToken } from '@angular/core';

export const DIRECTIONS = new InjectionToken('DIRECTIONS');
export const ACTIVE_DIRECTION = new InjectionToken("ACTIVE_DIRECTION");

export interface Direction {
    name: string;
    properties: any;
}

export interface DirectionOptions {
    directions: Direction[];
    active: string
}