import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'checkbox__018__001_001',
  templateUrl: './checkbox__018__001_001.component.html',
  styleUrls: ['./checkbox__018__001_001.component.scss']
})
export class Checkbox__018__001_001 implements OnInit {
  // @Input() formControlName: string;
  // @Input() type: string;
  // @Input() theme: string;
  // @Input() label: string;
  // @Input() value: any;
  @Input() input
  

  constructor() { }

  ngOnInit() {
    // this.input.label = '';
    // this.input.name='';
    // this.input.type ='';
    console.log("inputtesst=>", this.input)
  }

}
