import { Component, OnInit, Input } from '@angular/core';
import { GlobalService } from '../../services/global/global.service';
import { isFakeMousedownFromScreenReader } from '@angular/cdk/a11y';

@Component({
  selector: 'ngx-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnInit {
  @Input() score ;
  maxScore = 5;
  forDisplay = true;
  fromDataTable = false;
  range = [];
  marked = -1;

  constructor(private globalVar:GlobalService) { }

  ngOnInit() {
    this.fromDataTable = this.globalVar.fromDataTable;
    console.log("rating input",this.score)
    if(this.fromDataTable){
      this.score = this.globalVar.rate;
    }
    console.log(this.score)
    for (var i = 0; i < this.maxScore; i++) {
      this.range.push(i);
    }

    
  }
  public isMarked = (index) => {
      if (this.score >= index + 1) {
        return 'fas fa-star';
      }
      else if (this.score > index && this.score < index + 1) {
        return 'fas fa-star-half-alt';
      }
      else {
        return 'far fa-star';
      }
    
  }

}
