import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BASE_API_URL} from '../../../environments/environment';
import { ApiConstData } from '../../../consts/ApiConstData';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private httpClient: HttpClient,
              private apiConstants: ApiConstData,) { }


  GetProfileInfo(){
    return this.httpClient.post(`${BASE_API_URL}/details`,this.apiConstants.getHeaderandBodywithoutbackend().apiBody,{headers: this.apiConstants.getHeaderandBodywithoutbackend().apiHeaders})
  }
  


  GetLogInfo(LogUserID){
    let body= {
    "created_by":LogUserID
    }
    let newobj=
    {...this.apiConstants.getHeaderandBodywithoutbackend().apiBody, ...body}

    return this.httpClient.post(`${BASE_API_URL}/logs`,newobj,{headers: this.apiConstants.getHeaderandBodywithoutbackend().apiHeaders})
    }

}
