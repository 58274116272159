import { Injectable } from '@angular/core';
import { BASE_API_URL } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ApiConstData } from '../../../consts/ApiConstData';

@Injectable({
  providedIn: 'root'
})
export class PipelineService {
  baseURL = BASE_API_URL;
  headers;
  apiConstBody;
  constructor(private httpClient: HttpClient,
    private apiConstData: ApiConstData,) 
    {
      this.headers = apiConstData.getHeaderandBody().apiHeaders;
      this.apiConstBody =  apiConstData.getHeaderandBody().apiBody;
     console.log("constructor of form");
     }

     getAllStatus()
     {
       return this.httpClient.post<any>(`${this.baseURL}/requests/statuses`,this.apiConstBody,{headers:this.headers});
     }
     updateStatus(taskID,statusID)
     {
     
       let body={...{id:taskID,request_status_id:statusID},...this.apiConstBody};
       return this.httpClient.post<any>(`${this.baseURL}/requests/update`,body,{headers:this.headers});

     }
     updateDescription(taskID,Desc)
     {
     
       let body={...{id:taskID,description:Desc},...this.apiConstBody};
       return this.httpClient.post<any>(`${this.baseURL}/requests/update`,body,{headers:this.headers});

     }
     deleteTask(taskID)
     {
     
       let body={...{id:taskID},...this.apiConstBody};
       return this.httpClient.post<any>(`${this.baseURL}/requests/destroy`,body,{headers:this.headers});

     }

     


}
