import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConstData } from '../../../consts/ApiConstData';
import { BASE_API_URL} from './../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class TrelloService {
  baseURL = BASE_API_URL;
  headers;
  apiConstBody;
  constructor(private httpClient: HttpClient,
              private apiConstData: ApiConstData,
    ) { 
      this.headers = apiConstData.getHeaderandBody().apiHeaders;
      this.apiConstBody =  apiConstData.getHeaderandBody().apiBody;
     console.log("constructor of form")
    }

  getStages(apiLink,apiParameters){
    // let body = {...this.apiConstData.getHeaderandBody().apiBody}
    return this.httpClient.post<any>(`${apiLink}`,apiParameters,{headers:this.headers})
  }

  
}
