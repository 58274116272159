import { Injectable } from '@angular/core';
import { ApiConstData } from '../../../consts/ApiConstData';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  rate;
  actionsBtn;
  rowID;
  fromDataTable;
  cellSettings;
  moduleID;
  staticApi;
  apiParameters;
  compLoadedNum:number = 0;
  direction;
  trelloTasks =[];

  constructor(private apiConstant: ApiConstData,
              private httpClient: HttpClient
    ) { }

  setCompLoadedNum(){
    this.compLoadedNum+=1;
  }
  getResponse(apiUrl,apiBody,apiheader?) {
    let header = this.apiConstant.getHeaderandBody().apiHeaders
    return this.httpClient.post<any>(apiUrl,apiBody,{headers:header})
  }
  
  get_direction_class() {
    return sessionStorage.getItem('direction');
  }

  checkComponent(component: string,map) {
    return (map[component] == undefined ? {component_name:component,result:false} : {component_name:component,result:true});
  }

}
