import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    
    <div class="socials">
      <p style="font-size:13px;">تم التطوير بواسطة <a href="www.pentavalue.com">Pentavalue</a></p>
    </div>
  `,
})
export class FooterComponent {
}
