import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'action_card__003_005',
  templateUrl: './action_card__003_005.component.html',
  styleUrls: ['./action_card__003_005.component.scss']
})
export class Action_card__003_005 implements OnInit {
  @Output() resultList:EventEmitter<any> = new EventEmitter();
  resultArray:Array<Object> = [];
  cardDataObject = [
    {
      "parent_name":"Supplier",
      "parent_value":1,
      "children":[
        {"child_name":"Light",
        "child_value":1
        },
        {"child_name":"Audio",
        "child_value":2
        },
        {"child_name":"Decore",
        "child_value":3
        }
      ]
    },

    {
      "parent_name":"Media",
      "parent_value":2,
      "children":[
        {"child_name":"Media1",
        "child_value":1
        },
        {"child_name":"Media2",
        "child_value":2
        },
        {"child_name":"Media3",
        "child_value":3
        }
      ]
    },

    {
      "parent_name":"Test",
      "parent_value":3,
      "children":[
        {"child_name":"Test1",
        "child_value":1
        },
        {"child_name":"Test2",
        "child_value":2
        },
        {"child_name":"Test3",
        "child_value":3
        }
      ]
    },
  ]
  constructor() { }

  ngOnInit() {
    
  }
  showInfo(value) {
    if($(`#body_${value}`).hasClass('display--none')) {
      $(`#body_${value}`).removeClass("display--none flipOutX");
      $(`#body_${value}`).addClass('flipInX')
    }
    else {
      $(`#body_${value}`).removeClass('flipInX')
      setTimeout(()=>{
        $(`#body_${value}`).addClass('display--none');
  
        },700)
        $(`#body_${value}`).addClass('flipOutX')
    }
      
  }
  selectChildren(event,parent_name,parent_value,child,index) {
    event.stopPropagation();
    let resultObject = {};
    resultObject["parent_name"] = parent_name;
    resultObject["parent_value"] = parent_value;
    resultObject["child_name"]= child.child_name;
    resultObject["child_value"]= child.child_value;
    resultObject["ref"] = `element_${parent_value}_${child.child_value}`
    if(event.target.checked) {
      this.resultArray.push(resultObject);
      this.resultList.emit(this.resultArray);
    }
    else {      
     this.deleteItem(parent_value,child.child_value);
    }
  }

  deleteItem(parent_value,child_value) {
    let element = this.resultArray.find(item => item["ref"] == `element_${parent_value}_${child_value}`);
    if(element) {
      this.resultArray.splice(this.resultArray.indexOf(element),1);
      this.resultList.emit(this.resultArray)
    }
    else {
      return;
    }
  }

}
