import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'card__003_008',
  templateUrl: './card__003_008.component.html',
  styleUrls: ['./card__003_008.component.scss']
})
export class Card__003_008 implements OnInit {

  constructor() { }
  imageList=[1,2,3]

  ngOnInit() {
  }
 
}
