import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { TestServiceService } from './../../../../services/test_service/test-service.service';
import { Component, OnInit } from '@angular/core';
import { ApiConstData } from '../../../../../consts/ApiConstData';
import { MenuService } from '../../../../services/menu/menu.service';
import { GlobalService } from '../../../../services/global/global.service';
import { GlobalFunctions } from '../../../../../consts/global-functions';
import { Configurations } from '../../../../../consts/configurations';

@Component({
  selector: 'card__003_004',
  templateUrl: './card__003_004.component.html',
  styleUrls: ['./card__003_004.component.scss']
})
export class Card__003_004 implements OnInit {
  menuStructure ;
  apiLink: string;
  parameters: Object;
  moduleID: number;
  constructor(private testService: TestServiceService,
              private apiConstData:ApiConstData,
              private httpClient: HttpClient,
              private menuService: MenuService,
              private router: Router,
              private globalVariables: GlobalService,
              private GlobalFunctions: GlobalFunctions,
              public config: Configurations,

    ) {
   
   }

  ngOnInit() {
    // this.apiLink = 'https://appox.xyz/system/pentalibrary_test/public/api/menu'
    console.log("card_003_004_apiLink",this.apiLink);
    // this.httpClient.post<any>(`${this.apiLink}`,this.parameters,{headers: this.apiConstData.getHeaderandBody().apiHeaders}).subscribe(response=>{
    //   console.log("response card=> ",response)
    //   this.menuStructure = response.content.menuitems;
    //   console.log("menu itemcard_003_004,",this.menuStructure)
    // })

    //Testing
    this.httpClient.post<any>(`${this.apiLink}`,this.apiConstData.getHeaderandBody().apiBody,{headers: this.apiConstData.getHeaderandBody().apiHeaders}).subscribe(response=>{
      console.log("response card=> ",response)
      this.menuStructure = response.content.menuitems;
      this.globalVariables.setCompLoadedNum();
      console.log("menu itemcard_003_004,",this.menuStructure)
    },error=>{
      this.globalVariables.setCompLoadedNum();
      this.GlobalFunctions.showToastr('danger', 'Error', this.config.apiResponseMsg.danger.msg, this.config.apiResponseMsg.danger.icon)
    })
  }
  
  goTo(layoutID,moduleID,components,item){
      this.router.navigate([`${this.menuService.getLayoutByID(layoutID)}`],{queryParams:{children: JSON.stringify(item.children),moduleID: item.children[0].module_id,componentsList: JSON.stringify(item.children[0].components)}})
      // sessionStorage.setItem("children", JSON.stringify(item.children));

    }

}
