import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'penta-multiple-input',
  templateUrl: './multiple-input.component.html',
  styleUrls: ['./multiple-input.component.scss']
})
export class MultipleInputComponent implements OnInit {
  @Input() data;
  constructor() { }

  ngOnInit() {
  }

}
