import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { GlobalService } from '../../../../../../services/global/global.service';
@Component({
  selector: 'pipeline_card__012_001_002',
  templateUrl: './pipeline_card__012_001_002.component.html',
  styleUrls: ['./pipeline_card__012_001_002.component.scss']
})
export class Pipeline_card__012_001_002 implements OnInit {
  lists =[]
  constructor(private global: GlobalService) { }

  ngOnInit() {
    this.lists = this.global.trelloTasks
  }

  drop(event: CdkDragDrop<string[]>) {
    console.log("prev",event.previousContainer.id);
    console.log("container",event.container.id);
    console.log("/*/*/*/*/***/*/*/*//*/*/*/*");
    console.log("prev=> ",event.previousContainer);
    console.log("container=> ",event.container)


    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
    }
  }



}
