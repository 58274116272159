// import { messages } from './../app/pages/extra-components/chat/messages';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { Select2OptionData } from 'ng2-select2';
import { NbLayoutDirectionService, NbToastrService } from '@nebular/theme';
import { Injectable } from '@angular/core';
@Injectable ({
  providedIn:'root'
}) 
export class GlobalFunctions{
   toastrOptions={
    duration: 4000,
    destroyByClick: true,
    preventDuplicates: false,
    hasIcon: true,
   }
    constructor(
                private translateService: TranslateService,
                private directionService: NbLayoutDirectionService,
                private toastr:NbToastrService,
        ) {
        }

        globaltest(){
            console.log("test  global function")
        }
        
        setLangNDir(language){
            this.translateService.setDefaultLang(language);
            this.getNewDirection()
        }

        getNewDirection(){
            this.translateService.getTranslation(this.translateService.getDefaultLang()).subscribe(data=>{
              let translationData=data;
              let dir=translationData.SETTINGS.DIR
              this.toggleDirection(dir);
            })
          }

          toggleDirection(newDirection) {
            this.directionService.setDirection(newDirection);
            console.log("set Direction => ",newDirection)
          }
/**
 * 
 * @param type the type of toastr(success,danger,warning,default,primary)
 * @param title the title of toastr(optional)
 * @param txt the paragraph that appear under the title (optional)
 * @param icon the icon of toastr(optional) and it injects to the intialized variable (toastrOptions)
 */
  showToastr(type:string, title?:string, txt?:string, icon?:string){
    console.log("showToastr=> ")
    console.log("message=> ",txt)
    this.toastrOptions[`icon`] =icon;
    console.log(this.toastrOptions)
    switch(type){
      case 'success' :{
        this.toastr.success(title,txt,this.toastrOptions);
        break;
      }
      case 'danger' :{
        this.toastr.danger(title,txt,this.toastrOptions);
        break;
      }
      case 'default' :{
        this.toastr.default(title,txt,this.toastrOptions);
        break;
      }
      case 'primary' :{
        this.toastr.primary(title,txt,this.toastrOptions);
        break;
      }
      case 'warning' :{
        this.toastr.warning(title,txt,this.toastrOptions);
        break;
      }
    }
}

 handelErrorCode(code:number,validationErrors,msg?) {
  switch(code) {
    case 200: {
        this.showToastr('success', `${msg}`,'', 'fas fa-check');
        break;
    }
    case 403: {
      // this.GlobalFunctions.showToastr('danger', 'Please try again', 'Error', 'fas fa-times');
      validationErrors.forEach(error => {
          error.message.forEach(errorMsg => {
            this.showToastr('danger',`${errorMsg}`, this.translateService.instant('ERROR'), 'fas fa-times');
          })
      });
      break;
    }

    case 401: {
      
    }
    
  }
 }

 getDirection() {
  if(localStorage.getItem('lang_id') == '2') {
    return 'rtl'
  }
  else{
    return 'ltr'
  }
 }

  customInput () {
   
      $('.input2').each(function () {
          $(this).on('blur', function () {
              if ($(this).val() != "") {
                  $(this).addClass('has-val');
              }
              else {
                  $(this).removeClass('has-val');
              }
          })
      })
  }
}
