import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DirectionDirective } from './direction.directive';
import { DIRECTIONS, ACTIVE_DIRECTION } from './symbols';
import { DirectionService } from './direction.service';

@NgModule({
  imports: [
    CommonModule
  ],
  providers:[ DirectionService],
  declarations: [DirectionDirective],
  exports :[DirectionDirective]
})
export class DirectionModule { 
  static forRoot(options): ModuleWithProviders {
    return {
      ngModule: DirectionModule,
      providers: [
        {
          provide: DIRECTIONS,
          useValue: options.directions
        },
        {
          provide: ACTIVE_DIRECTION,
          useValue: options.active
        }
      ]
    }
  }
}
