import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import * as _moment from 'moment';
const moment = _moment;

// import moment = require('moment');

@Component({
  selector: 'date__010_002',
  templateUrl: './date__010_002.component.html',
  styleUrls: ['./date__010_002.component.scss']
})
export class Date__010_002 implements OnInit {
  @Output() getDateRange:EventEmitter<any> = new EventEmitter<any>()
  options ={
    format: 'MM/DD/YYYY',
    direction: 'ltr', // could be rtl
    weekLabel: 'W',
    separator: ' To ', // default is ' - '
    cancelLabel: 'Cancel', // detault is 'Cancel'
    applyLabel: 'Okay', // detault is 'Apply'
    clearLabel: 'Clear', // detault is 'Clear'
    customRangeLabel: 'Custom range',
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.monthsShort(),
    firstDay: 1 // first day is monday
}

  selected: any;
  alwaysShowCalendars: boolean;
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }
  constructor() {
    this.alwaysShowCalendars = true;
   }

  ngOnInit() {
  }
  getRange(event) {
    let rangeObject = {};
    rangeObject['startDate'] =  moment(event.startDate).format('DD-MM-YYYY');
    rangeObject['endDate'] = moment(event.endDate).format('DD-MM-YYYY');
    this.getDateRange.emit(rangeObject);
    console.log("range object", rangeObject)
  

  }
}
