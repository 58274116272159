import { ApiConstData } from './../../../../../consts/ApiConstData';
import { TestServiceService } from './../../../../services/test_service/test-service.service';
import { Component, OnInit, Input } from '@angular/core';
import { FormArray, FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { FormService } from '../../../../services/form/form.service';
import { GlobalFunctions } from '../../../../../consts/global-functions';
import { ActivatedRoute } from '@angular/router';
import { Configurations } from '../../../../../consts/configurations';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'tabs_basic__002_002',
  templateUrl: './tabs_basic__002_002.component.html',
  styleUrls: ['./tabs_basic__002_002.component.scss']
})
export class Tabs_basic__002_002 implements OnInit {
  @Input() formStructure;
  addItemForm: FormGroup;
  addForm: FormGroup;
  image: string;
  maxImageSize: number = 1024;
  imageName: string;
  imageSize: number;
  fields: Array<any> = [];
  keyValueList: FormArray;
  videoLinksList: FormArray;
  extraFields: Array<any> = [];
  extraFieldGp: Array<any> = [];
  staticApi: string;
  moduleID: number;
  refMainImg: boolean = false;
  datawithNewFormat = {};
  images = [];
  dropdownSettings;
  mainImg;
  loading: boolean = false;
  loadingConfig: any;
  formTaleData: any;
  selectedItems = {};
  formTabs;
  lang_form: FormGroup;
  defaultLang;
  languages = [];
  tabList = [];
  langObject = {};
  apiLink: string;
  apiParameters: HttpHeaders;

  languageFields: Array<Object> = [];
  activeLanguageFields: Array<Object> = [];


  constructor(public formBuilder: FormBuilder,
    private formService: FormService,
    private GlobalFunctions: GlobalFunctions,
    private activatedRoute: ActivatedRoute,
    public config: Configurations,
    private httpClient: HttpClient,
    private apiConstData: ApiConstData,
    private testService: TestServiceService,


  ) {

    this.activatedRoute.queryParams.subscribe(params => {
      this.staticApi = params.staticApi;
      this.moduleID = params.moduleID;
      console.log("static APi from query params => ", this.staticApi);
      console.log("static APi from query params => ", this.moduleID);
    })
    this.lang_form = formBuilder.group({
      'lang': [''],
    })

    /*Inside the add form 2 small forms (2 form arrays) filled this form array by function that function create control form*/
    this.addForm = formBuilder.group({
      taxonomy_terms: formBuilder.array([this.createKeyValueFields()]),
      videos: formBuilder.array([this.createVideoFields()]),
    })
  }

  ngOnInit() {
    console.log('====================================');
    console.log("input formStructure=> ",this.formStructure);
    console.log('====================================');
    // console.log("formFields_test services => ",this.testService.addFormFields.content)
    /*Loading Configurations*/
    this.loadingConfig = this.config.loadingConfig
    /*dropdown Multi Select*/
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'value',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true
    };

    this.getFields(this.moduleID)
    // this.getFields(41)
  }

  /*this function creats the controls of videos el mafrod el validators ana elly a7otha b2edy heana  f el case d*/
  createVideoFields(): FormGroup {
    return this.formBuilder.group({
      name: [''],
      link: ['']
    });
  }

  /*this functio creats the controls of extra fields el mafrod a7ot el validtars b2edy hena*/
  createKeyValueFields(): FormGroup {
    return this.formBuilder.group({
      key: [''],
      value: ['']
    });
  }

  /**this function push the created control of video in list in form array of videos control related to the add form */
  addVideoLinkField() {
    this.videoLinksList.push(this.createVideoFields());
  }

  addContact() {
    this.keyValueList.push(this.createKeyValueFields());
  }

  removeContact(index) {
    this.keyValueList.removeAt(index);
  }
  //return all group under Key and value
  get keyValueFormGroup() {
    return this.addForm.get('taxonomy_terms') as FormArray;
  }
  get extraFieldGroup() {
    return this.addForm.get('extra_fields') as FormArray;
  }
  get videoFieldsFormGroup() {
    return this.addForm.get('videos') as FormArray;
  }

  submitLangFields(langFieldsValue) {
    console.log("lang value => ", langFieldsValue);
    let lang_id = langFieldsValue.lang;
    delete langFieldsValue.lang;
    console.log("lang_id=> ", lang_id);
    if (lang_id == undefined) {
      return;
    }
    this.langObject = { ...this.langObject, ...{ [lang_id]: langFieldsValue } };
    console.log("langObject=> ", this.langObject);
  }
  bindValidations(validations: any) {
    if (validations.length > 0) {
      const validatorsList = [];
      validations.forEach(validator => {
        // validList.push(valid.validator);
        if (validator.name == 'minlength') {
          validatorsList.push(Validators.minLength(validator.validator_value));
        }
        else if (validator.name == 'maxlength') {
          validatorsList.push(Validators.maxLength(validator.validator_value));
        }
        else {
          validatorsList.push(Validators[`${validator.name}`]);
        }
      });
      return Validators.compose(validatorsList);
    }
    return null;
  }

  assignToLangForm(fields) {
    console.log("the default language =>", this.defaultLang)
    const group = this.formBuilder.group({});
    // console.log("languages fields =>>>>?//?/////", fields);

    fields.forEach(field => {
      if (field.type === "button") return;
      const control = this.formBuilder.control(
        field.value,
        this.bindValidations(field.validations || [])
      );
      group.addControl(field.name, control);
    })
    const lang = this.formBuilder.control('');
    group.addControl('lang', lang);
    this.lang_form = group;
  }

  createExtraFields(extraFields) {
    console.log("da5al el function ", extraFields)
    let group = {}
    extraFields.forEach(element => {
      console.log(element.name)
      console.log(element.id)

      group[element.id] = new FormControl('');
      this.extraFieldGp.push(element.id)
    })

    console.log("extra field Group", this.extraFieldGp)
    console.log("extrafield group form ", group)
    // this.addForm.get('extra_fields').setValue(group);
    return this.formBuilder.group(group);
  }

  changeLanguage(lang_id) {
    console.log("lang-id=> ", lang_id);
    this.activeLanguageFields = this.languageFields[`${lang_id}`];
    console.log("active lanaguae fields => ", this.activeLanguageFields)
  }

  getFields(moduleID) {
    
    let group = {};
    let validatorsList: Array<any> = [];
    this.loading = true;
    // this.formService.createCmsAddForm(moduleID, this.staticApi).subscribe(response => {
      // let response = this.formStructure;
      console.log("/*/*/*/*/********************************************")
      // console.log("response tabs content => ", response.content);
      this.formTabs = this.formStructure;
      this.apiLink = this.formTabs.api_form_submit;
      this.apiParameters = this.formTabs.parameters;
      console.log("apiLink=> ",this.apiLink);
      this.tabList = this.formStructure.tab_list;
      console.log("/*/*/*/*/********************************************");
      console.log("formTabs=>", this.formTabs);
      console.log("tab list =>", this.tabList);
      console.log("languages gp=> ",this.tabList[0].tab_content.groups[0].languages.length)

      this.tabList.forEach(element => {
        element.tab_content.groups.forEach(gp => {
          let fields = gp.fields;
          if (gp.languages.length > 0) {
            console.log("da5al el language condition")
            this.languageFields = gp.fields;
            console.log("languageFields from condition => ",   this.languageFields)
            this.defaultLang = gp.languages[0].value;
            console.log("defaultLang => ",   this.defaultLang)

            this.activeLanguageFields = gp.fields[`${this.defaultLang}`]
            console.log("//*/*/*/*/*/*/*/*/*/*/");
            console.log("activeLanguageFields => ",   this.activeLanguageFields)
            console.log("/*/*/*/*/*/*//*/*/*/*")
            this.assignToLangForm(gp.fields[`${this.defaultLang}`]);
            return;
          }
          fields.forEach(element => {
            console.log("element validation=> ", element.validations)
            // group[element.name] = new FormControl('');
            //**********************-Key & Value-******************** */
            if (element.name == 'taxonomy_terms') {
              group[element.name] = this.formBuilder.array([this.createKeyValueFields()])
            }
            /**************************-Videos-******************* */
            else if (element.name == 'videos') {
              group[element.name] = this.formBuilder.array([this.createVideoFields()])
            }
            /*************************-Extra Fields-************************* */
            else if (element.name == 'extra_fields') {
              console.log("extra field value", element.value)
              // this.createExtraFields(element.value)
              group[element.name] = this.formBuilder.array([this.createExtraFields(element.value)])
              this.extraFields = element.value
              console.log("=======", this.addForm.get('extra_fields') as FormArray);
            }
            else if (element.name == 'data') {
              return;
            }

            /****************************************-Normal Fields-*************************** */
            else {
              console.log("elements => ", element)
              console.log("validation list  => ", element.validations)

              /**looping over the validatons list if there are the validations fr this field */
              if (element.validations.length > 0) {
                element.validations.forEach(validator => {
                  if (validator.name == 'minlength') {
                    validatorsList.push(Validators.minLength(validator.validator_value));
                  }
                  else if (validator.name == 'maxlength') {
                    validatorsList.push(Validators.maxLength(validator.validator_value));
                  }
                  else if (validator.name == 'pattern') {
                    validatorsList.push(Validators.pattern(validator.validator_value))
                  }
                  else if (validator.name == 'required') {
                    validatorsList.push(Validators[`${validator.name}`]);
                  }
                })
              }

              group[element.name] = new FormControl('', Validators.compose(validatorsList));
            }
          })
          console.log("group form object => ", group)
          this.addForm = new FormGroup(group);
          this.keyValueList = this.addForm.get('taxonomy_terms') as FormArray;
          this.videoLinksList = this.addForm.get('videos') as FormArray;


          console.log("newfgvg=> ", this.addForm)

          console.log("fields =>", fields)
        })
      })

    // })



  }

  /**
   * this function allows you change the format of date
   * @param dateFormControlName :formControlName of date input
   */
  DateFormat(dateFormControlName) {
    let dateValue = this.addForm.get(`${dateFormControlName}`).value;
    const selectesDateTime = new Date(Date.parse(dateValue) - (new Date()).getTimezoneOffset() * 60000).toISOString().split('T')[0]
    this.datawithNewFormat[`${dateFormControlName}`] = selectesDateTime;
    console.log(this.datawithNewFormat)

  }

  /**
   * this function extracts the time from all date time format
   * @param timeFormControlName :formControlName of time input
   */
  timeFormat(timeFormControlName) {
    console.log("time Format")
    let timeValue = this.addForm.get(`${timeFormControlName}`).value;
    console.log("time Format", timeValue);

    this.datawithNewFormat[`${timeFormControlName}`] = timeValue.toString().slice(16, 21);
    // console.log("NewFormat", timeValue.toString().slice(16,21))
    console.log(this.datawithNewFormat)
  }

  /**
   * this function try to submit the values form to api 
   * @param formValue : value of input fields
   */
  submit(formValue) {
    console.log("Date formate => ", formValue)
    formValue[`images`] = this.images;
    formValue[`module_id`] = this.moduleID;
    formValue[`formTableData`] = this.formTaleData
    formValue[`lang_fields`] = this.langObject;
    // formValue[`name`] = "test"
    formValue = { ...formValue, ...this.datawithNewFormat ,...this.apiParameters}
    console.log("new form Value", formValue)
    console.log("images => ", this.images)
    console.log("apiLink for submit=>", this.apiLink)
    this.httpClient.post<any>(`${this.apiLink}`,formValue,{headers:this.apiConstData.getHeaderandBody().apiHeaders}).subscribe(response=> {
      console.log("submit response => ",response.status.code);
      console.log("response => ",response);
      this.GlobalFunctions.handelErrorCode(response.status.code,response.status.validation_errors);
    },error=> {
      this.GlobalFunctions.showToastr('danger', 'Please try again', 'Error', 'fas fa-times');
    })
    // this.formService.submitAddForm(formValue, this.staticApi).subscribe(response => {
    //   console.log("response", response);
    //   console.log("status =>", response.status)
    //   this.addForm.reset();
    //   this.GlobalFunctions.showToastr('success', 'the Form Submitted Successfully', 'Success', 'fas fa-check')
    // }, err => {
    //   console.log("error", err);
    //   this.GlobalFunctions.showToastr('danger', 'Please try again', 'Error', 'fas fa-times')
    // })
  }

  /**
   * this function reads the image file,converts it to base64 and injects the base64 data to datawithNewFormat object 
   * @param $event : change Event to get the file from it
   * @param formControlName : the formControlName of input file in this case (Image file);
   * 
   *Note (anyone work in this project please combine the two functions (readMainImage and readImage)):D 
   */
  readMainImage($event: any, formControlName): void {
    var file: File = $event.target.files[0];
    var reader: FileReader = new FileReader();

    reader.onloadend = (e) => {
      this.datawithNewFormat[`${formControlName}`] = reader.result;
    }
    reader.readAsDataURL(file);
  }

  readImage(event, ref, imageFormControlName) {
    console.log("ref of image => ", ref)
    var files = event.target.files;
    for (let i = 0; i < files.length; i++) {
      if (files) {
        this.imageName = files[i].name;
        this.imageSize = files[i].size;
        console.log("file name => ", files[i].name);
        console.log("file size => ", files[i].size);
        if (ref == 'main') {
          console.log("da5al el main ref")
          this.refMainImg = true;
          var reader = new FileReader();
          reader.onload = this.handleReaderLoaded.bind(this);
          reader.readAsBinaryString(files[i]);
        }
        else {
          this.refMainImg = false;
          console.log("da5al el not main el ref")
          var reader = new FileReader();
          reader.onload = this.handleReaderLoaded.bind(this);
          reader.readAsBinaryString(files[i]);
        }

      }
    }
  }


  handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;

    if (this.refMainImg == true) {
      this.mainImg = 'data:image/jpeg;base64,' + btoa(binaryString);
    }
    else {

      this.image = 'data:image/jpeg;base64,' + btoa(binaryString);
      if (this.imageSize / 1000 > this.maxImageSize) {
        this.images.push({
          'name': this.imageName,
          'size': this.imageSize,
          'index': this.images.length,
          'image': this.image,
          'class': 'red-class',
        });
      }
      else {
        this.images.push({
          'name': this.imageName,
          'size': this.imageSize,
          'index': this.images.length,
          'image': this.image,
          'class': 'default',
        });
      }

    }
    console.log("image Name => ", this.imageName)

    // this.images.push(this.image);

  }


  closebtn(index) {
    console.log("closebtn")
    console.log("value2")
    this.images.splice(index, 1);
    $.each(this.images, function (id, value) {
      value.index = id;
    });
  }

  onItemSelect(item: any) {
    console.log(item.value);
  }

  onSelectAll(items: any) {
    console.log(items.value);
  }

  getTableData(event) {
    this.formTaleData = event;
  }

  // clickSelect(event,base,name,target_url){
  //   console.log("event =>",event)
  //     if(base == '') {
  //       return;
  //     }
  //     else {
  //       if(this.selectedItems[`${base}`]) {
  //         let value = this.selectedItems[`${base}`].value;
  //         let object ={}
  //         object[`${base}`] = value;
  //         let body = {...this.apiConstData.getHeaderandBody().apiBody,...object}
  //         console.log("object=> ",object)
  //         this.loading = true;
  //         this.httpClient.post<any>(`${target_url}`,body,{headers:this.apiConstData.getHeaderandBody().apiHeaders})
  //         .subscribe(response => {
  //           this.loading = false;
  //           this.fields.forEach(element=> {
  //             if(element.name == `${name}`) {
  //               element.options = response
  //             }
  //           })

  //         },error => {
  //           this.loading = false;
  //           alert("jgjb")
  //         })
  //       }
  //       console.log("value of base => ",this.selectedItems[`${base}`].value)
  //     }
  // }

  changeSelect(event, name, base,gpIndex,tabIndex) {
    
    let fieldsOfGp = this.formTabs.tab_list[tabIndex].tab_content.groups[gpIndex].fields
  
    
    this.selectedItems[`${name}`] = { 'value': `${event.target.value}` };
    fieldsOfGp.forEach(element => {
      console.log("element.base =>",element.base)
      if (element.base == `${name}`) {
        console.log("element /*/*/*/*/*/*/*8/8/8/8/8=> ",element)
        let object = {};
        object[`${name}`] = event.target.value;
        console.log("object selet =>",object);
        let body = { ...this.apiConstData.getHeaderandBody().apiBody, ...object }
        this.loading = true;
        this.httpClient.post<any>(`${element.target_url}`, body, { headers: this.apiConstData.getHeaderandBody().apiHeaders })
          .subscribe(response => {
            console.log("dsjjds", response)
            element.options = response;
            this.loading = false;
          })
      }
    })
    console.log("selected items=> ", this.selectedItems)
  }

}
