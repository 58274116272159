import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "card__003_009",
  templateUrl: "./card__003_009.component.html",
  styleUrls: ["./card__003_009.component.scss"],
})
export class Card__003_009Component implements OnInit {
  @Input() data;
  constructor() {}

  ngOnInit() {
   console.log("card__003_009_data=> ",this.data)
  }
  
  downloadFile(fileLink) {
    window.open(`${fileLink}`);
    // console.log("The file link:",fileLink)
  }
}
