import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class TestServiceService {

/**
 * target_actions => {
 *  (1)buttons{
 *    targetaction{
 *                 -quick action Ex(accept and reject)
 *                 -modal Ex(add item)
 *                  }
 *                 }
 * 
 *  (2)layout 
 *  (3)external Link Ex(www.google.com)
 *  
 * 
 *          
 *           
 * }
 */

  constructor(private httpClient: HttpClient) { }

  

 
}




