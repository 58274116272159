import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'task_list__017_001',
  templateUrl: './task_list__017_001.component.html',
  styleUrls: ['./task_list__017_001.component.scss']
})
export class Task_list__017_001 implements OnInit {

  constructor() { }
  sort_id:number;
  tasks=[
    {id:"1",name:"App Core",time:"2 hours",check:true},
    {id:"2",name:"Screen Sample",time:"1 hour",check:false},
    {id:"3",name:"Localization",time:"2 hours",check:true},
    {id:"4",name:"DB",time:"4 hour",check:false},
    {id:"5",name:"Login Screen",time:"8 hours",check:false},
    {id:"6",name:"Validation",time:"3 hours",check:false},
    {id:"7",name:"UI",time:"2 hours",check:true},
    {id:"8",name:"Register Screen",time:"2 hours",check:false},
    {id:"9",name:"Popup Warning",time:"5 hours",check:true},
    {id:"10",name:"Api preparation",time:"1 hour",check:false}

  ];
  sortBy=[{id:"1",name:"Start Date"},{id:"2",name:"End Date"}]

  ngOnInit() {
    $(".menu option:eq(1)").attr('selected', 'selected')
  }
  getCustomerID() {
    console.log("sort_id=> ",this.sort_id)
    
  }

}
