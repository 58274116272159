import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'slider__011_001',
  templateUrl: './slider__011_001.component.html',
  styleUrls: ['./slider__011_001.component.scss']
})
export class Slider__011_001 implements OnInit {
  @Output() coordinates: EventEmitter<any> = new EventEmitter<any>()
  latitude: number;
  longitude: number;
  fromMap: Boolean = false;
  cardData: Array<Object> = [{ 'title': 'Place1', 'location': 'Abassya', 'lat': 30.08466271395318, 'lng': 31.314678403613243 },
  { 'title': 'Place2', 'location': 'Faculty of Engineering', 'lat': 30.064874657489405, 'lng': 31.27897329685061 },
  { 'title': 'Place2', 'location': 'Al-Rahman Al-Rahim Mosque', 'lat': 30.061531869781824, 'lng': 31.283736900061058 },
  ]
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    items: 2,
    navText: ["<i class='fas fa-chevron-left'></i>", "<i class='fas fa-chevron-right'></i>"],
    nav: true,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },

  }
  constructor() { }
  ngDoCheck() {
    if (this.fromMap == true) {
      $("owl-carousel-o").find('input:checkbox').prop('checked', false);
    }
  }
  ngOnInit() {
  }

  getCardData(event, data) {
    this.fromMap = false;
    $("owl-carousel-o").find('input:checkbox').prop('checked', false);
    event.target.checked = true
    this.latitude = data.lat;
    this.longitude = data.lng;
    let coordinates = {
      "lat": this.latitude,
      "lng": this.longitude
    }
    this.coordinates.emit(coordinates)
  }
  getCoordinatesFromMap(coordinates) {
    this.fromMap = true;
    this.coordinates.emit(coordinates)
  }

}
