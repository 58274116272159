import { DateComponent } from './../../components/form_components/date/date.component';
import { Directive, Input, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { FieldConfig } from '../../models/field-config.interface';
import { FormGroup } from '@angular/forms';
import { InputFieldsComponent } from '../../components/form_components/Input-fields/input-fields.component';
import { CheckboxComponent } from '../../components/form_components/checkbox/checkbox.component';
import { RadioButtonComponent } from '../../components/form_components/radio-button/radio-button.component';
import { SubmitButtonComponent } from '../../components/form_components/submit-button/submit-button.component';

const componentsMapper = {
  'input': InputFieldsComponent,
  'checkbox': CheckboxComponent,
  'radio_btn': RadioButtonComponent,
  'button': SubmitButtonComponent,
  'date': DateComponent,
}

@Directive({
  selector: '[dynamicField]'
})
export class DynamicFieldDirective {

  @Input() field: FieldConfig;
  @Input() formGp: FormGroup;
  componentRef: any;
  constructor(private resolver: ComponentFactoryResolver,
              private container: ViewContainerRef,
  ) { }

  ngOnInit() {
    /**
     * Use the resolveComponentFactory method of ComponentFactoryResolver to create the component factory based on field type defined in the configuration.
      Use the createComponent method of ViewContainerRef to create the component from the component factory.
      Pass field and group properties into dynamically created component via this.componentRef.instance.
     */
    const factory = this.resolver.resolveComponentFactory(componentsMapper[this.field.type]);
    this.componentRef = this.container.createComponent(factory);
    this.componentRef.instance.field = this.field;
    this.componentRef.instance.formGp = this.formGp;
  }

}

