import { TestServiceService } from './../../../../../services/test_service/test-service.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { HttpClient } from '@angular/common/http';
import { ApiConstData } from '../../../../../../consts/ApiConstData';
import { GlobalFunctions } from '../../../../../../consts/global-functions';
import { Configurations } from '../../../../../../consts/configurations';
import { GlobalService } from '../../../../../services/global/global.service';

@Component({
  selector: 'chart__004__002_001',
  templateUrl: './chart__004__002_001.component.html',
  styleUrls: ['./chart__004__002_001.component.scss']
})
export class Chart__004__002_001 implements OnInit {
  data: any;
  options: any;
  themeSubscription: any;
  chartData: any;
  chartType: string;
  apiLink;
  parameters;
  test;
  options_bar;
  options_pie;
  caption;
  subcaption;
  apiParameters;
  constructor(private theme: NbThemeService,
    private testService: TestServiceService,
    private httpClient: HttpClient,
    private apiConstData: ApiConstData,
    private GlobalFunctions: GlobalFunctions,
    public config: Configurations,
    private globalVariables: GlobalService,
  ) { }

  ngOnInit() {
    this.themeSubscription = this.theme.getJsTheme().subscribe(config => {
      const colors: any = config.variables;
      const chartjs: any = config.variables.chartjs;

      if (this.chartData.chart.type == 'pie2d') {
        this.chartType = "pie";
        this.options_pie = {
          maintainAspectRatio: false,
          responsive: true,
          scales: {
            xAxes: [
              {
                display: false,
              },
            ],
            yAxes: [
              {
                display: false,
              },
            ],
          },
          legend: {
            labels: {
              fontColor: chartjs.textColor,
            },
          },
        };
      }
      if (this.chartData.chart.type == 'column2d') {
        this.chartType = "bar"
        this.options_bar = {

        }
      }

      this.data = {
        labels: [],
        datasets: [{
          data: [],
          backgroundColor: [colors.successLight, colors.infoLight, colors.primaryLight, colors.infoLight, colors.infoLight, colors.successLight],
        }]
      }
      this.chartData.data.forEach(element => {
        this.data.labels.push(element.label);
        this.data.datasets[0].data.push(element.value)
      });
    });
    this.httpClient.post<any>(`${this.apiLink}`, this.apiParameters, { headers: this.apiConstData.getHeaderandBody().apiHeaders }).subscribe(response => {
      this.globalVariables.setCompLoadedNum();
      this.chartData = response;
      this.caption = response.chart.caption;
      this.subcaption = response.chart.subcaption;
      this.caption = this.chartData.chart.caption;
      this.subcaption = this.chartData.chart.subcaption;
      this.themeSubscription = this.theme.getJsTheme().subscribe(config => {
        const colors: any = config.variables;
        const chartjs: any = config.variables.chartjs;

        if (this.chartData.chart.type == 'pie2d') {
          this.chartType = "pie";
          this.options_pie = {
            maintainAspectRatio: false,
            responsive: true,
            scales: {
              xAxes: [
                {
                  display: false,
                },
              ],
              yAxes: [
                {
                  display: false,
                },
              ],
            },
            legend: {
              labels: {
                fontColor: chartjs.textColor,
              },
            },
          };
        }
        if (this.chartData.chart.type == 'column2d') {
          this.chartType = "bar"
          this.options_bar = {

          }
        }

        this.data = {
          labels: [],
          datasets: [{
            data: [],
            backgroundColor: [colors.successLight, colors.infoLight, colors.primaryLight, colors.infoLight, colors.infoLight, colors.successLight],
          }]
        }
        this.chartData.data.forEach(element => {
          this.data.labels.push(element.label);
          this.data.datasets[0].data.push(element.value)
        });
      });

    }, error => {
      this.globalVariables.setCompLoadedNum();
      this.GlobalFunctions.showToastr('danger', 'Error', this.config.apiResponseMsg.danger.msg, this.config.apiResponseMsg.danger.icon)
    })

    // this.chartData = this.testService.chartData;
    this.caption = this.chartData.chart.caption;
    this.subcaption = this.chartData.chart.subcaption;
    console.log("charts_compLoad Num => ",  this.globalVariables.compLoadedNum)
    this.themeSubscription = this.theme.getJsTheme().subscribe(config => {
      const colors: any = config.variables;
      const chartjs: any = config.variables.chartjs;
     
      if(this.chartData.chart.type == 'pie2d') {
        this.chartType = "pie";
        this.options_pie = {
          maintainAspectRatio: false,
          responsive: true,
          scales: {
            xAxes: [
              {
                display: false,
              },
            ],
            yAxes: [
              {
                display: false,
              },
            ],
          },
          legend: {
            labels: {
              fontColor: chartjs.textColor,
            },
          },
        };
      }
      if(this.chartData.chart.type == 'column2d') {
        this.chartType = "bar"
        this.options_bar = {

        }
      }

      this.data = {
        labels:[],
        datasets:[{
          data:[],
          backgroundColor: [colors.successLight, colors.infoLight, colors.primaryLight, colors.infoLight,  colors.infoLight, colors.successLight],
        }]
      }
      this.chartData.data.forEach(element=>{
        this.data.labels.push(element.label);
        this.data.datasets[0].data.push(element.value)
      });
      console.log("chart Dataiii=>",this.data);
    });



  

  }
  // ngOnDestroy(): void {
  //   this.themeSubscription.unsubscribe();
  // }
}
