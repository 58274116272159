import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'penta-img-input',
  templateUrl: './img-input.component.html',
  styleUrls: ['./img-input.component.scss']
})
export class ImgInputComponent implements OnInit {
  @Input() data;
 




  constructor() { }



  ngOnInit() {
    console.log("The Imageeeee", this.data);
    


  }

}
