import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'penta-normal-block',
  templateUrl: './penta-normal-block.component.html',
  styleUrls: ['./penta-normal-block.component.scss']
})
export class PentaNormalBlockComponent implements OnInit {
  @Input() data:Array<any>
  penta_normal_block;
  is_favourite:boolean = false;
  constructor() { }

  ngOnInit() {
    // this.penta_normal_block = [
    //   {
    //     'title':'Home Page',
    //     'sub-title': 'Products to be shown in home page',
    //     'favourite': true,
    //     'actions':[{'name':'Edit','icon':'fas fa-pen'},{'name':'Delete','icon':'fas fa-trash-alt'}]
    //   },
    //   {
    //     'title':'Home Page',
    //     'sub-title': 'Products to be shown in home page',
    //     'favourite': true,
    //     'actions':[{'name':'Edit','icon':'fas fa-pen'},{'name':'Delete','icon':'fas fa-trash-alt'}]
    //   }
    // ]
    this.penta_normal_block = this.data;
   
  }
  toggleFavourite(){
      this.is_favourite = !this.is_favourite
  }
}
