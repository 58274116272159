import { Component, OnInit } from '@angular/core';
import { TestServiceService } from '../../../../../services/test_service/test-service.service';
import { ApiConstData } from '../../../../../../consts/ApiConstData';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'chart__004__001_001',
  templateUrl: './chart__004__001_001.component.html',
  styleUrls: ['./chart__004__001_001.component.scss']
})
export class Chart__004__001_001 implements OnInit {
  
  width = "100%";
  height = "100%";
  dataFormat = "json";
  type ;
  dataSource;
  charts;
  apiLink: string;
  parameters: Object;
  modelID: number;
  isArray: boolean;
  

  constructor(private testService: TestServiceService,
              private apiConstData:ApiConstData,
              private httpClient: HttpClient,
    ) { 
  }

  ngOnInit() {
    console.log("simple_column__004__001_001(apiLink)=> ",this.apiLink);
    console.log("simple_column__004__001_001(parameters)=> ",this.parameters);
    console.log("simple_column__004__001_001(moduleID)=> ",this.modelID);

   console.log("apiiiiiiiii=> ",this.apiLink)

    // this.httpClient.post<any>(`${this.apiLink}`,this.parameters,{headers:this.apiConstData.getHeaderandBody().apiHeaders}).subscribe(response=>{
    //   console.log("imple_column__004__001_001=> ", response);
    //   this.charts = response.chart_data;
    //   console.log("chart response => ",response)
    //   // this.charts = this.testService.chartData;
    //   if(this.charts.isArray) {
    //     console.log("isArray => true")
    //     this.isArray = true;
    //   }
    //   else {
    //     console.log("isArray=> false")
    //     this.isArray = false;
    //   }
    // })

    /**(Testing) */
    console.log("chartsii=> ",this.charts)
    if(this.charts.isArray) {
      console.log("isArray => true")
      this.isArray = true;
    }
    else {
      console.log("isArray=> false")
      this.isArray = false;
    }

    // this.dataSource = this.testService.getChartData();
    // this.type = this.dataSource.chart.type;
    // console.log("dataSource => ", this.dataSource)
  }

}
