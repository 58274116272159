import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'penta-views',
  templateUrl: './views.component.html',
  styleUrls: ['./views.component.scss']
})
export class ViewsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
