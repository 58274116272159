import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { NbAuthService } from '@nebular/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: NbAuthService, private router: Router) {
  }
  canActivate() {
  
    if (localStorage.getItem('user-info')) {
      // let test = localStorage.getItem('user-info')
      // console.log(JSON.parse(test))

        return true;
    }

    this.router.navigate(['./login']);
    return false;
  }
  
}
